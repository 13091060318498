.shipping-policy-section
{
    padding: 30px 0;
}

.shipping-policy-section .shipping-content .ship-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}