.terms-condition-section
{
    padding: 30px 0;
}
.terms-condition-section .term-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.terms-condition-section ul li
{
    font-weight: 500;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.terms-condition-section .term-para .linking
{
    color: #777;
}