@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Montserrat&family=Raleway:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Montserrat&family=Raleway:wght@600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-error-msg
{
    color: #dc3545;
    text-align: start;
    font-size: 15px !important;
}

/* Toaster css */

.react-toast-notifications__toast--success{
    background-color: #495057 !important;
    color: #fff !important;
}

.react-toast-notifications__toast--success .react-toast-notifications__toast__icon-wrapper{
    background: #2f373f !important;
    
    color: #fff !important;
}

/* End */

.form-control:focus
{
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

body
{
    font-family: 'Lato', sans-serif;
}
.container
{
    max-width: 1080px !important;
}


a:-webkit-any-link:focus-visible {
    outline-offset:0;
}


/* pagination disabled btn */

.pagination-page-disabled-btn
{
    border: none !important;
  background-color: none !important;
  color: #666666 !important;
  cursor: text;
}
.page-link:focus
{
    outline: none !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 0 !important;
}
.page-link:hover
{
    background-color: transparent !important;
}
.pagination-page-active-btn
{
    background: red;
}
.activePage a {
    color: #B86097 !important;
    background-color: none;
}
.activePage .page-link
{
    color: #B86097 !important;
    background-color: none;
    font-weight: 700;
}
.page-link
{
    color: #212121 !important;
    padding: 10px !important;
    border: none !important;
}
.pagination
{
    justify-content: center;
}
/* App Loader*/
.app-loader
{
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


/* common css */
.common-heading
{
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2px;
    padding: 15px 0;
    text-align: center;
    font-family:'Playfair Display', serif;
}
.common-heading-2
{
    font-size: 34px;
    font-weight: 400;
    padding: 10px 0;
    text-align: start;
    font-family:'Playfair Display', serif;
}
.common-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding-top: 10px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.common-para::first-letter
{
    text-transform: capitalize;
}
.letter-space-one{
    letter-spacing: 1px!important;
}
.wishlist-fs
{
    font-size: 25px;
}
.new-arrival-wrapper:hover .Wishlist-hover-icon {
    color: red;
}
.overlay-class::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    background: rgba(0, 0, 0, 0.7);
}
.form-select:focus {
    border-color:inherit;
    outline: 0;
    box-shadow:none!important;
}


/* Disabled button css */

.disabled-btn
{
    background: grey;
    border: none;
    color: #fff;
    font-size: 14px !important;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
}


/* ------------------scroller--------------- */
.react-responsive-modal-modal::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.react-responsive-modal-modal::-webkit-scrollbar-track{
   
    background:#9f9999;

    border-radius: 10px;
}

/* Handle */

.react-responsive-modal-modal::-webkit-scrollbar-thumb {
    background:#d9d4d4;
    border-radius: 10px;
    height: 5px;
}


/* ----------breadcrumb----------------------- */

.cat-wrapper .bred-crumb-one{

    color: #777;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}
.cat-wrapper .separator{
    margin: 0 5px;
}
   .cat-wrapper .bred-crumb-two
   {

    font-size: 14px;
    font-weight: 600;
   }
   .cat-wrapper hr{
    border-top: 1px solid #e6dbdb;
   }
/* ----------breadcrumb----------------------- */


/* ------------------------common-btn css--------------------- */

.login-btn {
    background: #495057;
    border: none;
    color: #fff;
    font-size: 14px !important;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
}

.common-product-repeated-heading
{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400!important;
    letter-spacing: 0.7px;
    color: #202020;
    line-height: 1.7;
    text-align: center;
    /* text-transform: uppercase!important; */
    font-family: 'Lato', sans-serif;
}





/* -----------------------common btn css------------------- */

/* -----homepage start------------------ */

/* Carousel Css */

.carousel-section .carousel-inner img
{
    height: 560px;
}
.carousel-section .carousel-inner .carousel-item-boxes
{
    position: relative;
}
.carousel-section .carousel-inner .carousel-item-box-1
{
    position: absolute;
    top: 30%;
    left: 12%;
}
.carousel-section .carousel-inner .carousel-item-box-1 h1
{
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 20px;
}
.carousel-section .carousel-inner .carousel-item-box-1 p
{
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 400;
}
.carousel-section .carousel-inner .carousel-item-box-1 button
{
    border: none;
    
    
    padding: 15px 40px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 24px;
    border-radius: 4px;
    background: #fff;
    width: auto;
    z-index: 4;
    display: inline-block;
}
.carousel-section .carousel-inner .carousel-item-box-1 button:hover
{
    background: #6BA539;
}
.carousel-section .carousel-inner .carousel-item-box-2
{
    position: absolute;
    top: 20%;
    right: 12%;
}
.carousel-section .carousel-inner .carousel-item-box-2 img
{
    height: 351px;
    width: 300px;
}

/* Top Categories Css */

.categories-section
{
    padding: 40px 0;
}
.categories-section .top-categories
{
    padding: 10px;
    background: #e6e6e6;
    margin: 30px;
}
.categories-section .top-categories .categories-wrap
{
    background: #fff;
    position: relative;
    padding: 15px;
}
.categories-section .top-categories .categories-wrap p
{
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    position: absolute;
    background: #cac8d5b5;
    padding: 20px 0;
    text-align: center;
    border-radius: 2px;
    color: #212121;
    bottom: 14px;
    font-weight: 600;
    width: 96%;
    font-size: 20px;
}
.categories-section .top-categories .categories-wrap img
{
    width: 100%;
    object-fit: cover;
    max-height: 300px;
}

/* shipping-section css */

.shipping-section
{
    text-align: center;
    padding: 40px 0;
}
.shipping-section .truck-icon, .gift-icon, .money-icon
{
    font-size: 45px;
    color: #898989;
}
.shipping-section .shipping-wrapper h5, .gift-wrapper h5, .money-back-wrapper h5
{
    font-weight: 700;
    font-size: 16px;
}
.shipping-section .shipping-wrapper p, .gift-wrapper p, .money-back-wrapper p
{
    color: #AEAEAE;
    font-size: 15px;
    font-weight: 550;
}

/* Featured product css */

.featured-product-section
{
    padding: 40px 0;
}
.featured-product-section .owl-carousel .items
{
    background: #f2f2f2;
    padding: 34px 16px;
}
.featured-product-section .owl-carousel .item img
{
    height: 263px;
    width: 237px;
}
.featured-product-section .owl-carousel .item
{
    position: relative;
}
.featured-product-section .owl-carousel .item .new-wrap
{
    position: absolute;
    top: 8px;
    left: 8px;
    background: #2A3540;
    color: #fff;
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}
.featured-product-section .owl-carousel .item .discounted-wrap
{
    position: absolute;
    top: 8px;
    right: 8px;
    background: #2A3540;
    color: #fff;
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}
.featured-product-section .owl-carousel .item .title-wrap
{
    padding-top: 15px;
    font-size: 17px;
    margin-bottom: 9px;
    font-weight: 400;
}
.featured-product-section .owl-carousel .item .price-wrap
{
    color: #848484;
}
.featured-product-section .owl-carousel .item .price-wrap del
{
    color: #3A3A3A;
}
/* Discount section css */

.discount-section
{
    padding: 40px 0;
}
.discount-section .discount-wrapper
{
    display: flex;
    align-items: center;
    background: #EDEDED;
    padding: 40px;
}
.discount-section .discount-wrapper .discount-text

{
    padding-right: 130px;
}
.discount-section .discount-wrapper .discount-text a
{
    color: #EC5451;
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px solid #EC5451;
    padding-bottom: 3px;
}
.discount-section .discount-wrapper .discount-image img
{
    height: 200px;
}

/* new-arrival-section css */

.new-arrival-section 
{
    padding: 40px 0;
}
.new-arrival-section .new-arrival-image
{
    background: #f2f2f2;
    height: 185px;
    width: 100%;
}
.new-arrival-section .new-arrival-image img
{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.new-arrival-section .new-arrival-wrapper .new-arrival-text 
{
    display: flex;
    justify-content: space-between;
}
.new-arrival-section .new-arrival-text .texts
{
   font-weight: 500;
   font-size: 16px;
}

.new-arrival-section .add-button button
{
    border: none;
    background: none;
    line-height: 42px;
}
.new-arrival-section .add-button .btn-icon
{
    color: #78BE20;
    font-weight: 400;
    font-size: 32px;
}

.new-arrival-section .add-button .btn-icon:hover
{
    color: #6BA539;
}
.new-arrival-section .new-arrival-wrapper{
    position: relative;
    cursor: pointer;
}
.new-arrival-section .card-button button
{
    /* position: absolute;
    top: 0;
    right: 0; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 14px;
}
.new-arrival-section .new-arrival-wrapper:hover button
{
    visibility: visible;
    opacity: 1;
}

.chooseUs-section
{
    background: #f7f7f7;
    padding: 40px 0;
}
.chooseUs-section .Selection-Wrapper .selection-text
{
   text-align: center;
   padding-top: 20px;
}
.chooseUs-section .Selection-Wrapper .selection-text h4
{
    font-size: 17px;
    font-weight: 600;
}
.chooseUs-section .Selection-Wrapper .selection-text p
{
    color: #909090;
    font-size: 15px;
    font-weight: 500;
}
.chooseUs-section .Selection-Wrapper .selection-icon
{
    text-align: center;
}

/* Blog Css */

.blog-section
{
    padding: 50px 0;  
}
.blog-section .blog-image
{
    font-size: 15px;
    font-weight: 500;
}
.blog-section .blog-image img
{
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}
.blog-section h4
{
    color: #212121;
    font-weight: 600;
    font-size: 21px;
    margin: 0;
    font-family:'Playfair Display', serif;;
    border-bottom: 1px solid #f2f2f2;
    padding: 15px 0;
    cursor: pointer;
}
.blog-section .blog-text span
{
    cursor: pointer;
}

.blog-section .blog-text .blog-read-more {    
    color: #36312A;
    font-weight: 600;
    font-size: 14px;
}
.blog-section .blog-text a
{
    text-decoration: none;
    font-weight: 500;
    color: #B86097;
}
.blog-section .blog-image .blog-text
{
    background: rgba(255, 255, 255, 0.8);
}
.blog-section .blog-image .blog-text span:hover
{
    color: #B86097;
    font-weight: 500;
}
.blog-section .blog-image .blog-text p
{
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
    color: #171717b8;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   padding: 0;
   margin: 10px 0;
}
.blog-section .blog-image .blog-text p::first-letter
{
    text-transform: capitalize;
}
.blog-section .blog-image .blog-text a:hover
{
    font-weight: 600;
}
.blog-section .blog-image .blog-color-changed-1
{
    color: #41748D;
    font-size: 14px;
    font-weight: 500;
}
.blog-section .blog-image .blog-color-changed-2 a
{
    color: gray;
}
.blog-section .blog-image .blog-color-changed-2 a:hover
{
    color: #B86097 !important;
}
.blog-section .blog-image .blog-color-changed-2
{
    line-height: 0 !important;
}



.footer-inner-page-subheading{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-transform: uppercase;
}



/* media query */

@media only screen and (max-width : 1399px)
{
    
}
@media only screen and (max-width : 1199px)
{
    .carousel-section .carousel-inner .carousel-item-box-1 
    {
        top: 33%;
    }
    .carousel-section .carousel-inner .carousel-item-box-2 
    {
        top: 30%;
        right: 14%;
    }
.carousel-section .carousel-inner .carousel-item-box-2 img 
{
    height: 280px;
    width: 240px;
}
.carousel-section .carousel-inner .carousel-item-box-1 h1 
{
    font-size: 62px;
}
    .discount-section .discount-wrapper 
    {
        padding: 20px 10px;
    }
    .new-arrival-section .new-arrival-image
    {
    background: #f2f2f2;
    width: 100%;
    }
    .blog-section .blog-image 
    {
        height: 410px;
    }
}

.profile-wrapper .profile-sidebar .sidebox ul .siteactive a{
    color: #B86097 !important;
}

@media only screen and (max-width : 1024px)
{
    .carousel-section .carousel-inner .carousel-item-box-2 {
        top: 37%;
        right: 12%;
    }
    .carousel-section .carousel-inner .carousel-item-box-2 img 
    {
        height: 240px;
        width: 210px;
    }
    .carousel-section .carousel-inner .carousel-item-box-1 {
        top: 37%;
    }
    .carousel-section .carousel-inner .carousel-item-box-1 h1 {
        font-size: 54px;
    }
}
@media only screen and (max-width : 991px)
{

    .insta-icon
    {
        display: none !important;
    }
.login-btn {
    background: #495057;
    border: none;
    color: #fff;
    font-size: 14px !important;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
}
    .carousel-section .carousel-inner .carousel-item-box-1 h1 {
        font-size: 40px;
    }
    .shipping-section .shipping-wrapper h5, .gift-wrapper h5, .money-back-wrapper h5 
    {
        font-weight: 700;
        font-size: 15px;
    }
    .shipping-section .shipping-wrapper p, .gift-wrapper p, .money-back-wrapper p 
    {
        color: #AEAEAE;
        font-size: 13px;
        font-weight: 550;
    }
    .discount-section .discount-wrapper .discount-text 
    {
        padding-right: 0;
    }
    .blog-section h4
    {
        font-size: 16px;
    }
    .blog-section .blog-image .blog-text 
    {
        width: 100%;
        height: 100px;
    }
    .categories-section .top-categories
    {
        margin: 0;
    }
.categories-section .top-categories .categories-wrap img 
    {
        height: 220px;
    }

.new-arrival-section .add-button .btn-icon {
    font-size: 28px;
}
.new-arrival-section .empty
{
    display: none;
}
.blog-section 
{
    padding: 0;
}
.blog-section .blog-image {
    height: 330px;
}

.row.custom-gutter{
    --bs-gutter-x:0em!important;
}




}
@media only screen and (max-width : 767px)
{
    .blog-display
    {
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
    }
    .login-btn
    {
        font-size: 14px !important;
        padding: 10px 30px;
        margin-top: 10px;
    }
    .carousel-section .carousel-inner img {
        height: 490px;
    }
    .carousel-section .carousel-inner .carousel-item-box-1 button
    {
        padding: 12px 30px;
        margin-top: 0;
    }
    .carousel-section .carousel-inner .carousel-item-box-1 h1 {
        font-size: 30px;
    }
    .carousel-section .carousel-inner .carousel-item-box-2 img {
        height: 210px;
        width: 200px;
    }
    .discount-section .discount-wrapper {
        padding: 10px 20px;
        justify-content: space-between;
        margin: 10px 0;
        justify-content: space-between;
    }
    .new-arrival-section .changing-display
    {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .blog-section .blog-image {
        height: auto;
        margin: 10px 0;
    }
    .categories-section .top-categories .categories-wrap {
        margin: 6px;
    }
    .categories-section .top-categories
    {
        margin: 0 30px;
    }
}

@media only screen and (max-width : 575px) {
    .login-btn {
        font-size: 13px !important;
        padding: 6px 12px;
        margin-bottom: 10px;
        margin-top: 5px;
}
.common-heading-2
{
    font-size: 24px;
}

}
@media only screen and (max-width : 480px)
{
    .blog-section h4
    {
        font-size: 13px;
    }
    .blog-section .blog-image
    {
        font-size: 14px;
    }
    .blog-section .blog-image .blog-text p
    {
        font-size: 14px;
    }
    .blog-section .blog-image .blog-color-changed-1
    {
        font-size: 13px;
    }
    .text-error-msg
    {
    font-size: 13px !important;
    }
}
@media only screen and (max-width : 430px)
{
    .common-para
    {
        font-size: 14px;
    }
}
@media only screen and (max-width : 320px)
{
    .common-para
    {
        font-size: 7px;
    }
    .blog-section .blog-image .blog-text p
    {
        font-size: 8px !important;
    }
    .blog-section .blog-image .blog-text
    {
        height: 30px;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* .hidden-scrollbar::-webkit-scrollbar
{
  width: 0px!important;
} */

/* .custom-scrollbar::-webkit-scrollbar{
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background:#ffffff99;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background:  #b86097;
  border-radius: 10px;
  height: 5px;
} */

.disableScroll{
  overflow: hidden;
  height: 100vh;
}
.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  ._loading_overlay_content {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
  }
  
.text-success
{
    font-size: 14px;
    letter-spacing: 0.15px;
    font-weight: 400;
    color: green;
    font-family: 'Lato', sans-serif;
}
/* forget passwoord css */

.auth-wrapper .forget-password-wrapper
{
  display: flex;
  justify-content: end;
  padding-top: 15px;
}
.auth-wrapper .forget-password-wrapper p
{
  color: #212121;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  letter-spacing: 0.25px;
  cursor: pointer;
}
.auth-wrapper .forget-password-wrapper p:hover
{
  color: blue;
}
.auth-wrapper .forget-password-wrapper span:hover
{
  text-decoration: underline;
  color: #B86097;
  letter-spacing: 0.25px;
  font-weight: 600;
}

.invalid-feedback
{
  font-size: 13px !important;
}
.sidebar-logo {
    position: relative;
    display: flex;
    align-items: center;
}
.sidebar-logo .img-fluid {
    height: 210px;
}
.auth-wrapper
{
  height: 70vh;
}
.authantication-sidebar {
    display: flex;
    flex-direction: column;
}
.authantication-sidebar label
{
  color: #4c4b4bd9;
  font-size: 15px;
  margin-top: 5px;
}
.authentication-logo
{
  display: flex;
  justify-content: center;
}
.authentication-logo img
{
  height: 74px;
  width: 85px;
}
.authantication-heading h4 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .5px;
  text-align: center;
  color: #0a0a0ab0;
  font-size: 18px;
}
.auth-wrapper form .user-login-change {
  margin:15px 0 !important;
}

/* eye css */

.authantication-sidebar .eye-wrap {
  position: relative;
}

.authantication-sidebar .eye-wrapper {
  position: absolute;
  top: 5px;
  right: 9px;
  cursor: pointer;
}
.not-a-member:hover
{
  text-decoration: none !important;
  color: #212121 !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.auth-header
{
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.auth-header1
{
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  
}
.auth-header1 p:hover
{
  font-weight: 600;
  color:#B86097;
  text-decoration: underline;
}
.auth-header a
{
  color: #212121;
  font-weight: 400;
}
.auth-header a:hover , .auth-header p:hover
{
  font-weight: 600;
  color:#B86097;
  text-decoration: underline;
}
.auth-header span:hover
{
  font-weight: 600;
  color: #B86097;
  text-decoration: underline;
}

  .sidebar-text{
    text-transform: uppercase;
    color: white;
  }
  .sidebar-heading{
    font-size: 45px;
    font-weight: 400;
  }
  .sidebar-heading span{
       font-weight: 700;
  }

  .tagline {
    text-transform: uppercase;
    color: white;
  }
  .tagline
  {

    font-weight: 900;
    border-left: 5px solid #fff;
    padding-left: 12px;
    margin-top: 15px;
  }

  /* sidebar login modal */

  .react-responsive-modal-modal
  {
    max-width: 800px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 1rem;
    padding: 2rem;
    position: absolute;
    right: 0;
    overflow-y: auto;
    border-radius: 10px;
  }
  .react-responsive-modal-closeButton
  {
    display: none;
  }
  .react-responsive-modal-closeButton.modal-close-btn
  {
    display: block;
  }
  .react-responsive-modal-closeButton.modal-close-btn .modal-closed
  {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 5px;
    border: none;
    cursor: pointer;
    font-size: 26px;
    color: #444444;
    transition: 0.6s all;
    height: 32px;
    width: 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Placeholder css */

.auth-wrapper form .user-login-change-1 
{
	margin-top: 15px;
	margin-bottom: 20px;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus
{
  box-shadow: none;
}
.auth-wrapper form .user-login-change-1 input, .auth-wrapper form .user-login-change-2 input
{
  box-shadow: none !important;
  border: 1px solid #ced4da;
}
.auth-wrapper form .user-login-change-1 label, .auth-wrapper form .user-login-change-2 label
{
  color: #4c4b4bd9;
  font-size: 15px;
}


 /* default error icon and border color remove  */

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image:none!important;
  padding-right: 0 !important;
 
}
.form-control.is-invalid{
  border:1px solid #dc3545!important;
  padding-right: 0 !important;
}



/* Responsive */
@media only screen and (max-width :3840px){
  .auth-wrapper{
    height:auto;
    width:430px;
 }
 .auth-wrapper.single-register-page{
  height: auto;
 }
}
@media only screen and (max-width :2560px){
  .auth-wrapper{
    height:auto;
    width:400px;
 }
 .auth-wrapper.single-register-page{
  height: auto;
 }
}


@media only screen and (max-width :1920px){
  .auth-wrapper{
     height:auto;
     width:350px;
  }
  .auth-wrapper.single-register-page{
    height: auto;
   }

}
@media only screen and (max-width :1400px){
  .auth-wrapper{
    height:auto;
    width:auto;
 }
}
@media only screen and (max-width :1200px){
  .auth-wrapper{
    height:auto;
    width:auto;
 }
}


@media only screen and (max-width :1024px){
  .auth-wrapper{
    height: auto;
    width: auto;
  }
}

@media only screen and (max-width : 991px) {
  .react-responsive-modal-modal
  {
    padding-top: 30px !important;
  }
  .auth-wrapper{
    height:auto;
    width:auto;
 }
}

@media only screen and (max-width : 575px) {
  .auth-wrapper form .user-login-change-1 , .auth-submit-btn , .form-group , .auth-header
  {
    width: 300px;
  }
  .auth-wrapper form .user-login-change-1
  {
    margin-top: 0;
  }
  .increasing-width
  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .react-responsive-modal-modal
  {
    left: 0;
    top: 0;
    bottom: 0;
    padding: 1.5rem;
  }
  .authantication-heading h4
  {
    margin: 5px 0;
  }
  .auth-header
  {
    padding: 0;
  }
  .auth-wrapper form .user-login-change-2 input
  {
    margin: 10px 0;
  }
  .auth-wrapper form .user-login-change-2 .form-label
  {
    bottom: 8px;
  }
  .auth-wrapper form .user-login-change-1 .form-label
  {
    top: -10px;
  }
  .auth-wrapper form .user-login-change-1, .auth-submit-btn, .form-group, .auth-header{
    width: 100%;
  }
   .authantication-sidebar .auth-header1
  {
    padding-bottom: 0;
  }
 
}
@media only screen and (max-width : 430px) {
  .react-responsive-modal-modal
  {
    padding: 15px;
    padding-top: 5rem;
  }
  .auth-wrapper form .user-login-change-1 , .auth-submit-btn
  {
    width:100%;
  }
     .authantication-heading h4{
      margin: 0!important;
     }
      .invalid-feedback{
        line-height: 1.2;
        font-size: 12px!important;
      }
      .auth-wrapper .form-control{
        height: 33px;
      }
      .authantication-sidebar label {
        margin: 3px 0;
    }
}

@media only screen and (max-width : 360px) {
  .increasing-width{
    width: 100%;
  }
  .auth-wrapper form .user-login-change-1, .auth-submit-btn, .form-group, .auth-header{
    width: 100%;
  }

  .authantication-sidebar
  {
    align-items: center;
  }
  
  
}


@media only screen and (max-width : 320px) {
  .react-responsive-modal-modal
  {
    padding: 0;
  }
}

/* make nav bar active on click */

 header section nav .navbar-collapse .navbar-nav .nav-item.dropdown .nav-link.show, header section nav .navbar-collapse .navbar-nav .nav-item.dropdown #categoriesActive{
    color: #B86097!important;
}


.navigation-bar-1 .header-search-icon input:focus{
    border-bottom: none!important;
}


/* Header css */
.curated-collection {
    cursor: pointer;
}

.brdrs {
    border-bottom: 1px solid #f2f2f2;
}

/* mobile user icon */
.dropdown-menu.br-dr {
    border: 1px solid #e5e5e5 !important;
    position: absolute;
    top: 35px;
    left: 0;
    -webkit-transform: none;
            transform: none;
    width: 70%;
    background: #fff;
    list-style: none;
    margin-left: auto;
    z-index: 11;
    padding: 0;
}

.drop-down-menu.br-dr {
    border: 1px solid #e5e5e5 !important;
    position: absolute;
    top: 35px;
    left: 0;
    -webkit-transform: none;
            transform: none;
    width: 70%;
    background: #fff;
    list-style: none;
    margin-left: auto;
    z-index: 11;
    padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #212121;
}

.navigation-bar-1 .header-login-bar .mobile-view-user {
    display: none;
}

.mobile-user-view-icon {
    margin-top: 6px;
    margin-left: 6px;
    font-size: 16px;
}

/* mobile user icon end */
.navbar-toggler-icon {
    display: flex !important;
    align-items: center !important;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
    font-size: 20px;
    color: #212121;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

}

.accordion-box .mobile-search {
    display: none;
}

.accordion-box .accordion__button {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
}

.accordion-box .accordion__button:before {
    top: 24px;

}

.accordion-box-open {
    display: none;
    border: none;
    background: none;
    font-size: 20px;
}

.accordion-display-wrapper {
    display: none;
}

#mySidenav {
    /* display: none; */
    width: 0;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background: #fff;
    overflow-x: hidden;
    transition: 0.5s;
}

.accordion-box .closebtn {
    color: #a19c9c;
    font-size: 30px;
    text-decoration: none;
    display: flex;
    justify-content: end;
    padding-right: 8px;
    padding-top: 8px;
    border: none;
}
.accordion-box .closebtn svg:hover
{
    cursor: pointer;
}

.accordion-box .mobile-search input {
    border: none;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 0 10px 40px;
}

.accordion-box .mobile-search input::-webkit-input-placeholder {
    font-size: 16px;
}

.accordion-box .mobile-search input::placeholder {
    font-size: 16px;
}

.accordion__panel {
    padding: 10px 30px;
    background: #fff;
}

.accordion-box .mega_menu_item {
    margin-bottom: 10px;
    padding-left: 10px;
}

.accordion-box .mega_menu_item a {
    color: #444444;
    text-decoration: none;
    font-size: 14px;
}

.accordion-box .mobile-search {
    position: relative;
}

.accordion-box .mobile-search-icon {
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 20px;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.common-heading-3 {
    font-family: 'Playfair Display', serif;
    ;
    padding: 15px 0;
    font-size: 34px;
    font-weight: 400;
}

.common-para-3 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #5a5a5a;
}
.common-para-3::first-letter
{
    text-transform: capitalize;
}

.common-btn-2 {
    background: #e3e3e33d;
    outline: none;
    border: 1px solid #e5e5e5;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 300;
    color: #36312A;
    padding: 6px 10px;
    transition: 0.1s all ease-in-out;
    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
}

.common-btn-2:hover {
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    color: #fff;
    background-color:#6a6a6a!important;
    border: 1px solid #6a6a6a;
}

.header-shipping-banner .header-shipping-para {
    margin: 0;
    padding: 6px;
    font-size: 12px;
    background: #f2f2f2;
    font-family: 'Playfair Display', serif;
    ;
    font-weight: 600;
    letter-spacing: 1px;
}

.header-brand-name a {
    text-decoration: none;
}


/* accordion css */
.accordion-section-menu {
    width: 100%;
}

.accordion-section-menu .navbar-toggler {
    position: absolute;
    top: -50px;
}

.accordion-section-menu .accordion__button:before {
    top: 43%;
}

.accordion-section-menu .accordion {
    border: none;
}

.accordion-section-menu .accordion__button {
    background: #fff;
    padding: 12px 16px;
}

.accordion__button:hover {
    border-bottom: 1px solid #cdcbcb47;
}
.accordion__button:focus-visible
{
    outline: 0;
}

/* normal menu css */
.main_menu ul {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #f2f2f2;
}

.main_menu ul li {
    display: inline-block;
    position: relative;
}

.main_menu ul li a {
    font-size: 15px;
    color: #212121;
    padding: 10px 0;
    display: block;
    font-weight: 400;
    text-decoration: none;
}

.main_menu ul li .active,
.main_menu ul li:hover>a {
    color: #B86097;
    font-weight: 500;
    cursor: pointer;
}

.main_menu ul li {
    left: 100%;
    top: 0;
}

/* mega menu css */
.mega_menu_dropdown {
    position: static !important;
}

.mega_menu {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    margin-top: 50px;
}

.mega_menu_item {
    display: inline-block;
    padding-right: 80px;
}

.main_menu ul li:hover>.mega_menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0
}

.main_menu ul li .mega_menu_item a:hover {
    color: #B86097;
    font-weight: 500;
}

/* shop_2 css */
.shop_2 .mega_menu {
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 0 10px;
    margin: 0;
}

header {
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    top: 0;
    z-index: 5;
}

/* header search bar */

.navigation-bar-1 .header-search-icon .search-box-wrapper {
    display: none !important;
}
.navigation-bar-1 .header-search-icon input {
    font-size: 13px;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    padding-left: 10px;
    font-family: 'Playfair Display', serif;
    text-transform: capitalize;
}

.navigation-bar-1 .header-search-icon input::-webkit-input-placeholder {
    font-size: 13px;
    font-family: 'Playfair Display', serif;
}

.navigation-bar-1 .header-search-icon input::placeholder {
    font-size: 13px;
    font-family: 'Playfair Display', serif;
}

/* search bar ends */


.header-blog a {
    color: #212121;
    text-decoration: none;
    padding-right: 20px;
    font-size: 18px;
}

.header-blog a span {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
}

.header-blog a:hover {
    color: #B86097;
}

.header-blog a svg {
    height: 17px;
    width: 17px;
}

.header-blog a svg:hover path {
    fill: #B86097;
}

.navigation-bar-1 .header-brand-name h1 {
    font-size: 32px;
    color: #000;
    font-weight: 500;
    font-family: 'Playfair Display', serif;
}

.navigation-bar-1 .header-login-bar {
    display: flex;
    align-items: center;
    justify-content: end;
}

.navigation-bar-1 .cart-counter {
    position: relative;
}

.navigation-bar-1 .cart-number {
    position: absolute;
    top: -4px;
    right: -7px;
    background: #d9d8d8;
    color: #212121;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation-bar-1 .header-login-bar a {
    text-decoration: none;
    color: #212121;
    font-size: 20px;
}

.navigation-bar-1 .header-login-bar .header-cart-icon:hover {
    color: #B86097;
}

.navigation-bar-1 .header-login-bar .header-cart-icon:hover .cart-number {
    background: #B86097;
    color: #fff;
}

.navigation-bar-1 .header-login-bar .header-login-text {
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    padding-right: 10px;
    font-family: 'Lato', sans-serif;

}

.navigation-bar-1 .header-login-bar .header-login-text:hover {
    color: #B86097;
    
}

.navigation-bar-1 .header-login-bar .header-cart-icon {
    padding: 0 10px;
    font-size: 20px;
}

.header-login-bar-wrapper {
    position: relative;
}

.header-login-bar .dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0 1px 1px #20202033 !important;
    min-width: 12rem !important;
}

.drop-down-menu {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0 1px 1px #20202033 !important;
    min-width: 12rem !important;
}

.navbar-nav {
    width: 100%;
    justify-content: space-between;
}

.nav-link {
    color: #212121 !important;
    font-size: 15px!important;
    font-weight: 400;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-family: 'Playfair Display', serif;
}

.nav-link:hover {
    color: #212121 !important;
}

.dropdown-item:hover {
    color: #B86097 !important;
    background-color: transparent !important;
    cursor: pointer;
}

.dropdown-menu>li:hover {
    background-color: transparent;
}

.dropdown-item {
    font-size: 14px;
    padding: 10px 20px !important;
    font-family: 'Playfair Display', serif;
    font-size: 13px;
    letter-spacing: 0.35px;
}

.header-login-bar .dropdown-item-1 {
    font-size: 15px !important;
    padding: 12px 10px;
    display: block;
    width: 100%;
    font-weight: 400;
    font-family: 'Playfair Display', serif;
}

.header-login-bar .dropdown-item-2 {
    font-size: 15px !important;
    padding: 5px 10px;
    font-weight: 400;
    font-family: 'Playfair Display', serif;
}

.header-login-bar .dropdown-item-1:hover,
.header-login-bar .dropdown-item-2:hover {
    font-weight: 500;
    cursor: pointer;
    color: #B86097;
}

/* header css ends */

/* / Search / */

.header-search-icon .search-box-wrapper {
    display: none !important;
}

.header-search-icon #search-icon {
    display: flex;
    align-items: center;
    border: 1px solid #f2f2f2;
    margin-left: 10px;
    border-radius: 3px;
}

.header-search-icon #search-bar {
    cursor: pointer;
    padding: 4px 6px;
    border-right: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #212121b3 !important;
}

.header-search-icon input {
    font-size: 14px;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
}

.header-search-icon input::-webkit-input-placeholder {
    font-size: 14px;
}

.header-search-icon input::placeholder {
    font-size: 14px;
}

/* / search bar ends / */

.header-brand-name {
    justify-content: center;
}

/* --------sandeep dropdown css------- */


.after-login-menu {
    box-shadow: 0px 5px 8px 1px rgba(0,0,0,.1)!important;
    border-radius: 8px!important;
}


/* --------custom Modal css----- */
.show{
    display: block!important;
}


/* ------navbar toggler icon--- */
.navbar-toggler-icon{
    background-image:none!important;
}


.header-serach-close-wrapper
{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

/* media query */
@media only screen and (max-width : 3840px) {
 
}

@media only screen and (max-width : 1920px) {

    .navigation-bar .wish-list-box ul .nav-item .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-1 .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-hov .nav-link {
        font-size: 18px;
    }

    .header-search-box-2 {
        display: none;
    }
    

}


/* @media only screen and (max-width : 1600px) {

    
    .react-responsive-modal-modal.signupModal{
        width: 340px;
        padding: 20px;
        max-height: 500px;
    }
    .react-responsive-modal-modal
    {
        width: 340px;
        padding: 20px;
        max-height: 500px;
    }
} */



@media only screen and (max-width : 1400px) {

    .navigation-bar .wish-list-box ul .nav-item .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-1 .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-hov .nav-link {
        font-size: 15px;
    }
    .react-responsive-modal-modal.signupModal{
        width: 340px;
        padding: 20px;
        max-height: 95vh;
    }
    .react-responsive-modal-modal
    {
        width: 340px;
        padding: 20px;
        max-height: 95vh;
    }
}

@media only screen and (max-width : 1199px) {
    .navigation-bar .wish-list-box ul .nav-item {
        padding-left: 0;
        font-size: 14px;
    }

    .navigation-bar .wish-list-box ul .nav-item .nav-link {
        padding-left: 11px;
    }

    .navigation-bar .wish-list-box ul .nav-item-1 .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-hov .nav-link {
        padding-left: 8px;
    }
}

@media only screen and (max-width : 1024px) {}

@media only screen and (max-width : 991px) {
    .header-brand-name
    {
        justify-content: center;
    }
    .navigation-bar-1 .header-search-icon {
        display: none;
    }

    .accordion-box .mobile-search {
        display: block;
    }

    .navigation-bar-1 .header-brand-name h1 {
        font-size: 20px;
        margin: 0;
    }

    .accordion-box-open {
        display: block;
        padding-left: 0;
    }

    .navigation-bar-1 .accordion-box .active-btn {
        color: #B86097;
    }

    .navigation-bar-1 .accordion-box .mega_menu_item {
        margin-bottom: 10px;
    }

    .navigation-bar-1 .accordion-display-wrapper {
        display: block;
    }

    .navigation-bar-1 .header-blog {
        display: none;
    }

    .navigation-bar-1 .accordion-display-closed {
        display: none;
    }

    .navigation-bar .wish-list-box .navbar-toggler {
        background-color: #41748D;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
    }

    .navigation-bar .nav-right .header-sign-in p {
        border-left: none;
        padding-left: 0 !important;
    }

    .navigation-bar .wish-list-box ul .nav-item .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-1 .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-hov .nav-link {
        border-left: none;
    }

    .navigation-bar .wish-list-box ul .nav-item .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-1 .nav-link,
    .navigation-bar .wish-list-box ul .nav-item-hov .nav-link {
        padding-left: 17px;
    }
   

    .main_menu ul {
        flex-wrap: wrap;
        border-top: none;
    }

    .main_menu ul li {
        width: 100%;
    }

    .navigation-bar-1 .navbar-toggler:focus {
        box-shadow: none;
    }

    .navigation-bar-1 .navbar-light .navbar-toggler {
        position: absolute;
        top: -54px;
        padding: 0;
    }

    .navbar-light .navbar-toggler {
        border: none;
    }

    .shop_2 .mega_menu {
        padding: 0 10px;
        background: #f2f2f2;
        display: block;
    }

    .mega_menu_item {
        padding: 0;
        display: block;
    }
}

@media only screen and (max-width : 767px) {
    .navigation-bar-1 .cart-number {
        top: -3px;
        right: -4px;
        height: 18px;
        width: 18px;
    }

    .navigation-bar-1 .header-brand-name h1 {
        font-size: 18px;
        margin: 0;
        padding-left: 0;
    }

    .header-brand-name {
        justify-content: center;
    }

    .navigation-bar-1 .navbar-light .navbar-toggler {
        position: absolute;
        top: -54px;
    }

    .header-search-icon .search-box-wrapper {
        display: block !important;
    }

    .header-search-box-2 {
        display: block;
    }

    .header-search-box-2 .search-box-wrapper {
        position: relative;
    }

    .header-search-box-2 .search-box-wrapper input {
        border: none;
        border-bottom: 1px solid #f2f2f263;
        background: none;
        padding-left: 10px;
        width: 50%;
    }

    .search-bars {
        position: absolute;
        top: 0;
        right: 50%;
        color: #c7c4c4;
    }

    .navigation-bar-1 .header-login-bar {
        justify-content: end;
    }
}

@media only screen and (max-width : 575px) {
    .padding-removed {
        padding-left: 0 !important;
    }

    .header-login-text {
        display: none;
    }

    .mega_menu_item {
        padding: 0;
    }

    .navigation-bar-1 .header-brand-name h1 {
        font-size: 18px !important;
        font-weight: 600;
    }

    .header-brand-name {
        justify-content: center;
    }

    .navigation-bar-1 .navbar-light .navbar-toggler {
        top: -49px;
        font-size: 17px;
        padding: 2px 8px;
    }

    .navigation-bar-1 .header-login-bar .header-login-text {
        font-size: 14px;
        display: none;
    }

    .navigation-bar-1 .header-login-bar .mobile-view-user {
        display: none;
    }

    .navbar-toggler-icon {
        font-size: 20px !important;
    }

    .navigation-bar-1 .header-login-bar .mobile-view-user {
        display: block;
    }
   
    .react-responsive-modal-modal{
        max-width: 100%;
        width: auto;
        padding: 30px 60px;
        max-height: -webkit-max-content;
        max-height: max-content;
    }
    .react-responsive-modal-modal.signupModal{
        max-width: 100%;
        width: auto;
        padding: 30px 60px;
        max-height: -webkit-max-content;
        max-height: max-content;
    }
    .navigation-bar-1 .header-login-bar .header-cart-icon
    {
        padding: 0 15px;
    }
    .header-login-bar-wrapper
    {
        padding-bottom: 6px;
    }
   
}

@media only screen and (max-width : 480px) {
    .navigation-bar-1 .header-brand-name h1 {
        font-size: 17px !important;
    }

    .navbar-toggler-icon {
        font-size: 19px !important;
    }
    .react-responsive-modal-modal{
       
        padding: 20px 20px;
       
    }
    .react-responsive-modal-modal.signupModal{
        
        padding: 20px 20px;
       
    }
    .authentication-logo img
    {
        height: 70px;
        width: 80px;
    }
    .authantication-heading h4
    {
        font-size: 16px;
    }
    .auth-wrapper form .user-login-change-1 label, .auth-wrapper form .user-login-change-2 label
    {
        font-size: 14px;
    }
    .authantication-sidebar input.form-control{
        height: 30px;
      }
      .auth-wrapper form .user-login-change-1
      {
        margin-bottom: 10px;
      }
}

@media only screen and (max-width : 430px) {
    .header-shipping-banner .header-shipping-para {
        font-size: 11px;
    }

    .navbar-toggler-icon {
        font-size: 18px !important;
    }
    .react-responsive-modal-modal.signupModal
    {
        padding: 20px;
    }
    .react-responsive-modal-modal
    {
        padding: 20px;
    }
     .react-responsive-modal-modal.signupModal .login-btn{
        margin:0 0 3px 0!important;

     }
}
@media only screen and (max-width : 420px) {
    .mobile-user-view-icon{
        margin-left: 3px;
        font-size: 14px;
    }
    .navigation-bar-1 .header-login-bar .header-cart-icon{
        padding: 0 8px;
    }
}
@media only screen and (max-width :396px) {
    .navigation-bar-1 .header-login-bar .header-login-text {
        font-size: 13px;
    }
  
   
}

@media only screen and (max-width :388px) {
    .navigation-bar-1 .header-brand-name h1 {
        font-size: 16px !important;
    }
}

@media only screen and (max-width : 380px) {
    .navigation-bar-1 .header-login-bar .header-cart-icon
    {
        padding: 0 10px;
    }
}
@media only screen and (max-width : 360px)
{
    .react-responsive-modal-modal.signupModal
    {
        padding: 20px 0px;
    }
    .react-responsive-modal-modal
    {
        padding: 20px 0px;
    }
}
@media only screen and (max-width :353px) {
    .navigation-bar-1 .header-brand-name h1 {
        font-size: 15px !important;
    }
}

@media only screen and (max-width : 332px) {
    .navigation-bar-1 .header-brand-name h1 {
        font-size: 14px !important;
    }
}

@media only screen and (max-width :321px) {
    .navigation-bar-1 .header-login-bar .header-login-text {
        font-size: 12px;
    }

    .navigation-bar-1 .navbar-light .navbar-toggler {
        top: -45px;
        font-size: 14px;
        padding: 2px 7px;
    }

    .navbar-toggler-icon {
        font-size: 16px !important;
    }

    .navigation-bar-1 .header-login-bar .header-cart-icon {
        font-size: 17px;
    }

    .navigation-bar-1 .header-login-bar .mobile-view-user {
        font-size: 17px;
    }

    .navigation-bar-1 .cart-number {
        font-size: 9px;
    }

    .navigation-bar-1 .cart-number {
        top: -6px;
        right: -5px;
        height: 17px;
        width: 17px;
    }
}

@media only screen and (max-width : 315px) {
    .navigation-bar-1 .header-brand-name h1 {
        font-size: 14px !important;
    }
}
.subscribed-email{
    text-align: center;
    margin: 0;
    color: green;
    font-size: 14px;
    margin-top: 5px;
}
.footer-section
{
    background: #41748D;
    padding: 40px 0;
    color: #fff;
}
.footer-section .icon
{
    font-size: 16px;
    padding-right: 7px;
    display: flex;
}
.footer-section h2
{
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 30px;
    margin: 0;
}
.footer-section .footer-store
{
    font-size: 14px;
}
.footer-section .footer-icon-wrapper .address, .footer-section .footer-tel
{
    line-height: 1;
}
.footer-section .product-names ul, .footer-section .company-names ul
{
    list-style: none;
    padding-left: 0;
    font-size: 15px;
}
.footer-section .product-names ul li, .footer-section .company-names ul li
{
    padding-bottom: 10px;
}
.footer-section .footer-clients-views .icon-1
{
    font-size: 30px;
    padding-right: 30px;
}
.footer-section .footer-clients-views .client-opinion-1
{
    font-size: 14px;
}


/* footer blog */
.footer-page .footer-blog a
{
    color: #212121;
    text-decoration: none;
    padding-right: 7px;
    font-size: 18px;
    font-family: 'Playfair Display', serif;
}
.footer-page .footer-blog a span
{
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
}
.footer-page .footer-blog a:hover
{
    color: #B86097;
}
.footer-page .footer-blog a svg
{
    height: 20px;
}
.footer-page .footer-blog a svg:hover path
{
    fill: #B86097;
}
.footer-page.footer-blog
    {
        display: none;
    }
.footer-page .text-end
{
    font-family: 'Playfair Display', serif;
    letter-spacing: 0.50px;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 0;
}

/* Email Css */

.email-section
{
    padding: 16px 0;
}
.email-section .email-icon-1 .mail-icon
{
    color: #EC5451;
    font-size: 24px;
    display: flex;
    padding-right: 10px;
}
.email-section .email-icon-1 .update-box .update-text
{
    font-weight: 600;
}
.email-section .email-icon-2 .e-mail-box
{
    width: 300px;
}
.email-section .email-icon-2 .e-mail-box input
{
    width: 100%;
    padding: 7px 15px;
    background: #ededed;
    border: none;
    font-size: 14px;
}
.email-section .email-icon-2 .e-mail-box input::-webkit-input-placeholder
{
    font-weight: 500;
    color: #888B8D;
}
.email-section .email-icon-2 .e-mail-box input::placeholder
{
    font-weight: 500;
    color: #888B8D;
}
.email-section .email-icon-2 .send-box .send-icon
{
    background: #EC5451;
    color: #fff;
    padding: 2px 10px;
    font-size: 20px;
}


/* Designed by Css */

.designed-by-section
{
    padding: 6px 0;
    background: #f2f2f2;
}
.designed-by-section .designed-box p
{
    color: #525252;
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 0;
}
.designed-by-section .designed-box span
{
    color: #b86097;
    font-weight: 500;
    font-size: 12px;
}
 .designed-by-section .designed-box span img
 {
     width: 65%;
 }
.designed-by-section .designed-wrapper
{
    display: flex;
    justify-content: space-between;
}
.giks-link
{
    text-decoration: none;
    color: #b86097;
}
.giks-link:hover
{
    color: #b86097;
}
.footer-links{
    font-weight: 600;
    color: #B86097!important;
}


/* responsive */

@media only screen and (max-width : 767px)
{
    .footer-page
    {
        padding-top: 20px !important;
    }
    .footer-page .footer-border
    {
        padding: 20px 0 !important;
        margin-top: 20px !important;
    }
    .subscribed-email
    {
        padding: 0 !important;
        margin-bottom: 10px;
        margin-top: 0;
        line-height: 1;
    }
    .footer-section .footer-clients-views
    {
        display: none;
    }
    .footer-page .footer-mha-logo
    {
        display: none;
    }
    .footer-page .text-end
    {
        padding-bottom: 0;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (max-width:575px) {
    .footer-page .footer-stay-touch h5
    {
        font-size: 17px;
    }
    .footer-page .footer-email input
    {
        padding: 0;
    }
    .footer-page .footer-email input::-webkit-input-placeholder
    {
        font-size: 10px!important;
    }
    .footer-page .footer-email input::placeholder
    {
        font-size: 10px!important;
    }
    .footer-page
    {
        padding: 10px 0;
    }
    .footer-page .footer-border
    {
        margin-top: 20px;
        padding-top: 0;
    }
    
}



@media only screen and (max-width:575px)
{
    .designed-by-section .designed-box span img
    {
        width: 100%;
    }
    .designed-by-section .powered-box{
        font-size: 13px;
    }
}
@media only screen and (max-width: 399px) {
    .designed-by-section .designed-box p
    {
        font-size: 9px;
    }
}
@media only screen and (max-width: 385px) {
    .designed-by-section .designed-box p
    {
        font-size: 8px;
    }
}
@media only screen and (max-width: 372px) {
    .designed-by-section .designed-box p
    {
        font-size: 7px;
    }
}
@media only screen and (max-width:366px){
    .designed-by-section .designed-box span
    {
        font-size: 9px;
    }
}
.carousel-demo .product-item .product-item-content {
    position: relative;
    border-radius: 3px;
    margin: .3rem;
    padding: 5px;
    cursor: pointer;
}
.carousel-demo .product-item .product-item-content .card-button button
{
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 15px;
}
.carousel-demo .product-item .product-item-content:hover button
{
    visibility: visible;
    opacity: 1;
}
.carousel-demo .product-item .product-item-content .product-name
{
    display: flex;
    justify-content: space-between;
}
.carousel-demo .product-item .product-item-content .product-name .add-button button
{
    border: none;
    background: none;
    line-height: 28px;
}
.carousel-demo .product-item .product-item-content .product-name .add-button .btn-icon
{
    color: #78BE20;
    font-weight: 400;
    font-size: 35px;
}

.carousel-demo .product-item .product-item-content .product-name .add-button .btn-icon:hover
{
    color: #6BA539;
}
.carousel-demo .product-item .product-item-content h4
{
    font-size: 16px;
    font-weight: 600;
}
.carousel-demo .product-item .product-item-content .product-badge
{
    display: none;
}
.carousel-demo .card
{
    border: none;
}
.carousel-demo .p-carousel-indicators 
{
    display: none;
}
.carousel-demo .product-item .product-image {
    width: 100%;
    height: 220px;
    object-fit: cover;
    padding: 15px;
    background: #ededed;
}
.carousel-demo .product-item .product-item-content:hover .Wishlist-hover-icon {
    color: red;
}





/* responsive */


@media only screen and (max-width : 991px)
{
    .carousel-demo .product-item .product-image {
        width: 100%;
        height: 210px;
    }
    .carousel-demo .product-item .product-item-content {
        height: 310px;
    }
    .carousel-demo .product-item .product-item-content h4
{
    font-size: 11px;
    font-weight: 700;
}
.carousel-demo .product-item .product-item-content h6 {
    font-size: 14px;
}
.carousel-demo .product-item .product-item-content .product-name .add-button .btn-icon {
    font-size: 28px;
}
}

@media only screen and (max-width : 767px)
{
    .carousel-demo .product-item .product-image {
        width: 100%;
        height: 148px;
    }
    .carousel-demo .product-item .product-item-content {
        height: 255px;
    }
}

@media only screen and (max-width : 480px)
{
    .carousel-demo .product-item .product-image {
        width: 100%;
        height: 215px;
    }
}
iframe
{
    width: 100%;
}
.video-demo-section .demo-1
{
    padding-top: 70px;
}
.video-demo-section .demo-1 h4
{
    font-family: 'Playfair Display', serif;
    padding: 15px 0;
    font-size: 34px;
    font-weight: 400;
}
.video-demo-section p
{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #5a5a5a;
}
.video-demo-section .demo-2
{
    width: 100%;
}

.back-to-top-icon
{
    position: fixed;
    color: #212121;
    bottom: 20px;
    right: 30px;
    font-size: 30px;
}
.back-to-top-icon svg:hover
{
    color: #B86097;
    cursor: pointer;
    z-index: 20;
}

.class {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.class::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.asterisk::after
{
    content: '*';
    color: red;
}
._3xnQP
{
    z-index: 3 !important;
}

.homepage-content .summer-table-content , .homepage-content .the-sea-content , .homepage-content .plates-content , .homepage-content .the-sea-content
{
    padding: 0 40px;
    text-align: justify;
}
.homepage-content .summer-table-image , .homepage-content .the-sea-image-1, .homepage-content .the-sea-image-1 , .homepage-content .plates-image , .homepage-content .black-forest-image
{
    padding: 40px 10px;
}
.homepage-content .summer-table-image img , .homepage-content .the-sea-image-1 img ,.homepage-content .the-sea-image-2 img, .homepage-content .plates-image img , .homepage-content .black-forest-image img
{
    cursor: pointer;
    height: 460px;
    width: 100%;
    object-fit: cover;
}

.homepage-featured-products
{
    padding: 40px 0;
}
.homepage-featured-products .feature-text
{
    border-top: 1px solid #dfdada;
    padding: 20px 0;
}
.homepage-featured-products .feature-product-box-1 .box-1-image-1 , .homepage-featured-products .feature-product-box-1 .box-1-image-2 , .homepage-featured-products .feature-product-box-3 .box-3-image-1 , .homepage-featured-products .feature-product-box-3 .box-3-image-2
{
    text-align: center;
    padding: 10px;
    cursor: pointer;
}
.homepage-featured-products .feature-product-box-2 .box-2-image
{
    text-align: center;
    margin: 60px 0;
    cursor: pointer;
}
.homepage-featured-products .feature-product-btn
{
    display: flex;
    justify-content: center;
}
.homepage-featured-products .feature-btn
{
    background: #4D4D4D;
    padding: 6px 100px;
    border: 1px solid #212121;
    text-transform: uppercase;
    border: none;
    font-weight: 400;
    letter-spacing: .7px;
    border-radius: 2px;
    font-size: 13px;
    color: #fff;
    font-family: lato;
}
.homepage-featured-products .box-1-images img , .homepage-featured-products .box-3-images img
{
    height: 250px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0px 7px 10px rgba(51, 37, 11, 0.15));
            filter: drop-shadow(0px 7px 10px rgba(51, 37, 11, 0.15));
}
.homepage-featured-products .box-2-image img 
{
    height: 500px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0px 7px 25px rgba(51, 37, 11, 0.15));
            filter: drop-shadow(0px 7px 25px rgba(51, 37, 11, 0.15));
}
.homepage-featured-products .common-para
{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400!important;
    letter-spacing: 0.7px;
    color: #202020;
    text-align: center;
  
    font-family: 'Lato', sans-serif;
}
.homepage-featured-products .feature-product-price
{
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #5a5a5a;
    font-family: 'Lato', sans-serif;
}
.homepage-follow-modern-wrap
{
    padding: 30px 0;
}
.homepage-follow-modern-wrap .follow-up-email-text
{
    border-top: 1px solid #dfdada;
}

.homepage-content  img{
    height: 100%!important;
}


.footer-page
{
    padding-top: 50px;
    background: #f2f2f2;
}
.footer-page .footer-email
{
    display: flex;
    justify-content: center;
}
.footer-page .footer-email input
{
    border: none;
    padding: 7px 40px;
    background: #fff;
}
.footer-page .footer-email input::-webkit-input-placeholder
{
    letter-spacing: 1px;
    color: #777;
}
.footer-page .footer-email input::placeholder
{
    letter-spacing: 1px;
    color: #777;
}
.footer-page .footer-email input::-webkit-input-placeholder
{
    font-size: 11px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 400;
}
.footer-page .footer-email input::placeholder
{
    font-size: 11px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 400;
}
.footer-page .footer-email button
{
    border: none;
    padding: 11px 20px 8px 20px;
    background: #4D4D4D;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: lato;
}
.footer-page .footer-insta-logo
{
    display: flex;
    justify-content: center;
    border: 0!important;
}
.footer-page .footer-insta-logo a
{
    color: #212121;
    font-size: 24px;
}
.footer-page .footer-border
{
    border-top: 1px solid #dfdada;
    padding: 40px 0;
    margin-top: 40px;
    border-bottom: 1px solid #dfdada;
}
.footer-page .footer-info-links a , .footer-page .footer-policy-links a
{
    display: block;
    text-decoration: none;
    color: #5a5a5a;
    font-weight: 400;
    font-size: 13px;
    padding: 4px 0;
    font-family: 'Lato', sans-serif;
}
@media only screen and (min-width:990.5px) {
    .footer-page .footer-info-links a:hover , .footer-page .footer-policy-links a:hover
    {
    font-weight: 600;
    color: #B86097;
    }
}

/* common footer-heading */

.footer-heading
{
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    
    
}

.footer-para
{
    line-height: 24px;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 13px;
    color: #5a5a5a;
    margin-bottom: 0;
    font-family: 'Lato', sans-serif;
}
.footer-para::first-letter
{
    text-transform: capitalize;
}

/* .footer-page .footer-mha-logo
{
    padding-top: 50px;
} */

.footer-page .footer-mha-logo img
{
    height: 150px;
    background: #fff;
    padding: 15px;
    object-fit: contain;
    cursor: pointer;
}
.odd-alignment
{
    flex-direction: row-reverse;
    align-items: center;
}
.homepage-content .my-excerpt {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    line-height: 21px;
    font-size: 14px;
}







/* responsive */


@media only screen and (max-width : 1199px)
{
    .footer-page .footer-email input
    {
        padding: 0 18px;
    }
    .footer-page .footer-insta-logo a
    {
        font-size: 23px;
    }
}
@media only screen and (max-width : 991px)
{
    .homepage-content .summer-table-content, .homepage-content .the-sea-content, 
    .homepage-content .plates-content, .homepage-content .the-sea-content {
        padding: 0;
    }
    .homepage-featured-products .box-2-image img
    {
        height: 350px;
    }
    .homepage-featured-products .box-1-images img, .homepage-featured-products .box-3-images img {
        height: 160px;
    }
    .common-heading-3
    {
        font-size: 30px;
    }
    .common-para-3
    {
        font-size: 14px;
    }
    .common-btn-2
    {
        font-size: 13px;
    }
    .homepage-content
    {
        padding-bottom: 20px;
    }
    .homepage-follow-modern-wrap
    {
        padding-top: 30px;
    }
    .footer-page .footer-email
    {
        justify-content: start;
    }
    .footer-page .footer-email button {
        padding: 12px 20px 12px 27px;
        font-size: 10px;
        font-weight: 500;
    }
    .footer-page .footer-email input
    {
        padding: 0 15px;
    }
    .footer-page .footer-stay-touch h5
    {
        text-align: center !important;
    }
    .footer-page .footer-email
    {
        justify-content: center;
        margin: 15px 0;
    }
    .footer-page .footer-mha-logo {
        padding: 30px 0;
    }
    .footer-heading {
        padding-top: 20px;
        margin: 0;
    }
    .navigation-bar-1 .header-nav-links a
    {
        font-size: 11px;
    }
}

@media only screen and (max-width : 767px) {

.padding-formobile-view
{
    padding: 0 12px;
}
.homepage-featured-products .feature-product-box-2 .box-2-image
    {
        padding: 0;
    }
.homepage-featured-products
{
    padding: 0;
}
.homepage-featured-products .feature-text
{
    padding: 0;
}
.common-heading
{
    padding: 10px 0;
}
.homepage-featured-products .box-1-images img, .homepage-featured-products .box-3-images img {
    height: 290px;
}
.homepage-featured-products .feature-product-btn
{
    margin: 20px 0;
}
.homepage-content .summer-table-image, .homepage-content .the-sea-image-1 , .homepage-content .the-sea-image-1, .homepage-content .plates-image, .homepage-content .black-forest-image
{
    padding: 40px 0;
}
    .homepage-follow-modern-wrap
    {
        padding-top: 15px;
    }
    .common-heading-3 {
        font-size: 19px;
        padding: 2px 0;
        font-weight: 500;
    }
    .common-btn-2
    {
        padding: 6px;
    }
    .common-heading
    {
        font-size: 19px !important;
    }
    .the-sea-image-1
    {
        display: block;
    }
}

@media only screen and (max-width:575px){
    .homepage-featured-products .feature-product-box-2 .box-2-image
    {
        margin: 0;
    }
    .common-heading-3 
    {
        font-size: 20px;
    font-family: Playfair Display,serif;
    padding: 0;
    font-weight: 500
    }
    .common-para-3
    {
        font-size: 13px;
        margin-bottom: 12px;
    }
    .common-btn-2
    {
        font-size: 12px;
    }
    .product-description .product-details .price-wrapper-3
    {
        line-height: 20px !important;
    }
    .homepage-featured-products .box-1-images img, .homepage-featured-products .box-3-images img {
        height: 210px;
        object-fit: contain;
    }
    .homepage-featured-products .feature-product-box-1 p, .homepage-featured-products .feature-product-box-2 p, .homepage-featured-products .feature-product-box-3 p
    {
        font-size: 14px;
    }
    .homepage-featured-products .box-2-image img
    {
        padding-top: 0;
        height: auto;
    }
    .common-heading
    {
        font-size: 20px !important;
    }
    .homepage-featured-products .feature-btn
    {
        padding: 6px 50px;
    }
    .homepage-content .summer-table-image img , .homepage-content .the-sea-image-1 img ,.homepage-content .the-sea-image-2 img, .homepage-content .plates-image img , .homepage-content .black-forest-image img
    {
        height: 350px;
    }
}

@media only screen and (max-width:480px) {
    .common-heading-3 {
        font-size: 18px;
    font-family: Playfair Display,serif;
    padding: 0;
    font-weight: 500
    }
    .common-para-3 {
        font-size: 12px;
        margin: 0;
    }
    .common-btn-2 {
        font-size: 11px;
        padding: 5px;
        margin-top: 7px;
    }
    .common-heading {
        font-size: 20px !important;
    }
    .homepage-featured-products .box-1-images img, .homepage-featured-products .box-3-images img {
        height: 160px;
    }
    .homepage-featured-products .feature-product-box-1 p, .homepage-featured-products .feature-product-box-2 p, .homepage-featured-products .feature-product-box-3 p {
        font-size: 12px;
    }
    .homepage-featured-products .box-2-image img {
        height: 370px;
    }
    .homepage-featured-products .feature-btn {
        padding: 3px 35px;
        font-size: 15px;
    }
    .homepage-featured-products .feature-product-box-1 p, .homepage-featured-products .feature-product-box-2 p, .homepage-featured-products .feature-product-box-3 p {
        font-size: 12px;
        padding-top: 0;
    }
    .footer-heading
    {
        padding-top: 0;
        font-size: 14px;
    }
    .footer-page .footer-info-links a, .footer-page .footer-policy-links a {
    font-size: 12px;
    }
    .footer-para
    {
        font-size: 12px;
        line-height: 18px;
    }
    .footer-page {
        padding: 10px 0;
    }
    .homepage-content .summer-table-image, .homepage-content .the-sea-image-1 , .homepage-content .the-sea-image-1, .homepage-content .plates-image, .homepage-content .black-forest-image
    {
        padding: 20px 0;
    }
}

@media only screen and (max-width:480px)
{
    .feature-product-name-price{
       margin: 10px 0 5px 0;
       font-size:14px!important;
    }
    .feature-product-price{
        font-size:14px!important;
    }
}

@media only screen and (max-width:430px) {
    .homepage-featured-products .feature-btn
{
    padding: 6px 16px;
    font-size: 12px;
}
}


@media only screen and (max-width:400px)
{
    .homepage-featured-products .box-2-image img {
        height: 300px;
    }
}


@media only screen and (max-width:380px) {
    .footer-page .footer-email button
    {
        padding: 12px;
    }
}
@media only screen and (max-width:343px) {
    .footer-page .footer-email button
    {
        padding: 5px;
    }
}
@media only screen and (max-width:320px) {
    .homepage-content .summer-table-image img , .homepage-content .the-sea-image-1 img ,.homepage-content .the-sea-image-2 img, .homepage-content .plates-image img , .homepage-content .black-forest-image img
    {
        height: 250px;
    }
}
._2R-kh {
    display: flex;
    flex-wrap: wrap;
  }
  ._wPDyp {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100%/6 - 10px);
    margin: 5px;
    display: block;
    position: relative;
  }
  ._vzTHL {
    display: block;
    width: 100%;
    height: 100%;
    transition: .25s;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  ._3xnQP {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    opacity: 0;
  }
  ._3xnQP:hover {
    opacity: 1;
    background-color: #00000061;
  }
  @media (max-width: 480px) {
    
    /* CSS */
    ._wPDyp{
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100%/2 - 10px);
      margin: 5px;
    }
    
  }
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    ._wPDyp {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100%/3 - 10px);
      margin: 5px;
    }
    
  }

  ._3lhLL{
    text-align: center;
    color: red;
    font-size: 18px;
  }



   ._1I_qj {
  position: relative;
}

._3qwMT {
  position: absolute;
  padding-bottom: 67%;
  margin-bottom: 4rem;
  width: 100%;
  background: #ffdd28;
  opacity: 1;
  transition: opacity 2s ease;
}

._3rVly {
  opacity: 0;
}

/* 404 page css */

.error-page {
    height: 100vh;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
}

.error-page-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-page-wrapper {
    text-align: center;
}

.error-page-wrapper h1 {
    font-size: 220px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    margin-bottom: 0;

}

.error-page-wrapper h1 span.two {
    color: #4d4d4d;
}

.error-page-wrapper h1 span.one {
    color: #000;
}

.error-page-msg {
    font-size: 22px;
    font-weight: 500;
    color: #525252;
}

.error-page-btn {
    display: inline-block;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid transparent;
    text-transform: uppercase;
    padding: 7px 25px;
    font-size: 14px;
    border-radius: 40px;
    margin: 7px;
    transition: .2s all;
    font-family: 'Roboto', sans-serif;
}

.error-page-btn.home {
    background: #4d4d4d;
    color: #fff;
}

.error-page-btn.contact {
    border: 2px solid #4d4d4d;
    color: #525252;
}

@media only screen and (max-width : 576px)
{
    .error-page-wrapper h1 {
        font-size: 140px;
    }

    .error-page-msg {
        font-size: 18px;
    }
}

/* carousel */
.carousel-view {
z-index: -1 !important;
}

/* soldout css */
.product-description .single-image-detail
{
    position: relative;
}
.product-description .single-image-detail .sold-out-image
{
    position: absolute;
    top: 0;
    right: 14%;
    width: auto;
    height: 60px !important;
    object-fit: contain;
    display: none;
}

  /* cart toast * */
.add-to-bag-animated
{
    display: flex;
    justify-content: end;
    align-items: center;
    background: #495057;
    padding: 10px;
    border-radius: 3px;
    position: fixed;
    top: 25%;
    right: 25px;
}
.add-to-bag-animated img
{
    height: 50px;
    object-fit: contain;
}
.add-to-bag-animated p
{
    font-size: 14px;
    margin-bottom: 0;
    font-family: playfair;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0 10px;
    color: #f2f2f2;
}
.animate__animated.animate__fadeInRightBig {
    --animate-duration: .5s;
  }
  
  :root {
    --animate-duration: 1000ms;
    --animate-delay: 0.9s;
  } 


/* single-image-zoom-views */

.product-description .desktop-view-image
{
    display: block;
}
.product-description .mobile-view-image
{
    display: none;
}

/* multi-carousel-css */
.product-description .react-multiple-carousel__arrow
{
    width: 30px !important;
    height: 30px !important;
    z-index: 4 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
}
.react-multiple-carousel__arrow--right {
    right:4px;
}
.react-multiple-carousel__arrow--left {
    left:4px;
}
.react-multiple-carousel__arrow::before
{
    font-size: 12px !important;
}
.product-description .react-multiple-carousel__arrow--left::before
{
    
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    -webkit-transform: rotate(-48deg);
            transform: rotate(-48deg);
    position: relative;
    left: 2px;
    bottom: 1px;
}
.product-description .react-multiple-carousel__arrow--right::before
{
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    -webkit-transform: rotate(134deg);
            transform: rotate(134deg);
    position: relative;
    right: 2px;
    bottom: 1px;
}
.react-multi-carousel-track
{
    display: flex;
    align-items: center;
}

/* carousel Ends */
.product-description .quantity-button button{
    border: 0;
    background: #fff;
    width: 80%;
    padding: 5px 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);

}
.product-description .quantity-input input
{
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.product-description .quantity-button button svg{
    font-size:10px;
}
.product-description
{
    padding: 30px 0;
    /* position: relative; */
}
.random::after
{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #333;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .7;
    z-index: 9;
}
.product-description .single-image-detail
{
    padding: 5px;
}
.product-description .single-image-detail img{
    height: 390px !important;
    width: 100%;
    object-fit: contain;
    cursor: pointer;
}

.quantity-input input{
    padding:10px;
}
.product-description .product-details
{
    margin-left: 40px;
    margin-top: 20px;
}
.product-description .product-details .add-button button
{
    background: #78BE20;
    padding: 7px 22px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 4px;
}
.product-description .product-details .add-button button:hover
{
    background: #6BA539;
}
.product-description .product-details .title-wrap
{
    display: flex;
    padding-bottom: 10px;
    font-size: 22px;
    text-align: start;
    /* text-transform: capitalize; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
}
.product-description .product-details .wishlist-div {
    display: flex;
}
.product-description .product-details .wishlist-btn {
    border: none;
    display: flex;
    font-size: 26px;
    color: #D8D7DF;
}
.product-description .product-details .wishlist-btn svg:hover {
    color: #B86097;
    cursor: pointer;
}
 .wishlisted-btn  {
    color: #B86097 !important;
}
.newWishlistedBtn{
    color: #B86097!important;
}
.product-description .product-details .wishlist-text {
    color: #78BE20;
    font-size: 14px;
}
.product-description .product-details .price-wrap
{
    font-size: 18px;
}
.product-description .product-details .price-wrapper-1
{
    padding: 8px 12px;
    background: #F7F7F7;
    font-size: 15px;
    font-weight: 450;
    margin: 0 10px;
}
.product-description .product-details .price-wrapper-2
{
    color: #878787;
    font-weight: 500;
}
.product-description .product-details .price-wrapper-3
{
    line-height: 30px;
    border: 1px solid #d8d8d8;
    padding: 20px;
    margin-top: 10px;
}
.product-description .product-details .product-detail-box-1
{
    padding: 10px 0;
}
.product-description .product-details .product-detail-box-1 .specification-of-para
{
    padding-left: 20px;
}
.product-description .product-detail-box-1 .para-styling , .tabs-section .para-styling
{
    margin: 18px 0 20px;
    color: #777;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
}
.product-description .product-detail-box-1 .para-styling::first-letter, .tabs-section .para-styling::first-letter
{
    text-transform: capitalize;
}
.product-description .product-details .add-button .btn-text
{
    font-size: 12px;
    letter-spacing: .5px;
    padding-left: 7px;
}
.product-description .multiple-images
{
    padding: 25px 0;
}
.product-description .multiple-images .multiple-image-1
{
    margin: 10px;
}
.product-description .multiple-images .multiple-image-1 img
{
   height: 100px;
   width: 100%;
   object-fit: contain;
    padding: 5px;
    background: #EFEFEF;
    cursor: pointer;
}

/* modal */

.modal-section .modal-left-text
{
    border-bottom: 1px solid #808084;
    padding: 4px 0;
}
.modal-section .modal-quantity-text
{
    display: flex;
    justify-content: center;
    padding: 29px 0 0 50px;
}
.modal-section .modal-quantity-text h2
{
    font-size: 13px;
    color: #808080;
    font-weight: 600;
    letter-spacing: 1px;
}
.modal-section .modal-left-text h4
{
    color: #57bb6c;
    font-size: 16px;
    letter-spacing: .5px;
}
.modal-section .modal-right-text 
{
    padding: 20px 0;
}
.modal-section .modal-right-text h3
{
    color: #808084;
    font-size: 19px;
}
.modal-section .modal-right-text ul li
{
    color: #808084;
    list-style: none;
    padding: 5px 0;
    letter-spacing: 1px;
    font-size: 14px;
}
.modal-section .modal-right-text .modal-btn .btn-1
{
    background: #fd9235;
    border: none;
    color: #fff;
    padding: 6px;
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 14px;
}
.modal-section .modal-right-text .modal-btn .btn-2
{
    background: #1F2534;
    color: #fff;
    border: none;
    padding: 6px;
    text-transform: uppercase;
    font-size: 14px;
}

/* modal ends */

.product-description .tabs-section .nav-link.active
{
    background: #6BA539 !important;
    color: #fff;
}
.product-description .tabs-section .nav-link
{
    background: #78BE20;
    color: #fff;
}
.product-description .tabs-section .nav-link:hover
{
    background: #6BA539;
    color: #fff;
}
.product-description .tabs-section .tab-content .tab-1
{
    border: 1px solid #D8D7DF;
    padding: 20px;
    color: #848684;
    font-size: 14px;
}

.product-description .tabs-section .tab-content .tab-1 p
{
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}
.product-description .tabs-section .tab-content .tab-1 p::first-letter
{
    text-transform: capitalize;
}
.product-description .tabs-section .tab-2
{
    border: 1px solid #D8D7DF;
    padding: 20px;
    line-height: 30px;
    font-size: 14px;
}
.product-description .tabs-section .tab-2-box-1 .product-image-box
{
    border: 1px solid #D8D7DF;
    padding: 10px;
    width: 14%;
}
.product-description .tabs-section .tab-2-box-1 .product-text-box
{
    padding-top: 10px;
}
.product-description .tabs-section .tab-2-box-1 .product-text-box span
{
    font-size: 14px;
    letter-spacing: 1px;
}
.product-description .tabs-section .tab-2-box-1 .product-text-box b
{
    padding-right: 10px;
    font-size: 14px;
    letter-spacing: 1px;
}
.product-description .tabs-section .tab-3
{
    border: 1px solid #D8D7DF;
    padding: 20px;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-1
{
    display: flex;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrap
{
    color: #808080;
    font-weight: 700;
    font-size: 14px;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-1 .sub-box-1
{
    padding-right: 100px;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-1 .sub-box-2
{
    padding-left: 20px;
    border-left: 1px solid #D8D7DF;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-1 .sub-box-2 p , .product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-2 p
{
    color: #80848F;
    font-size: 14px;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-3 button
{
    background: #78BE20;
    border: none;
    padding: 11px 15px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    border-radius: 5px;
}
.product-description .tabs-section .tab-3-box-1 .sub-box-wrapper-3 button:hover
{
    background: #6BA539;
}


/* you might like page */

.product-description a
{
    text-decoration: none;
    color: #212121;
}
.product-description a:hover
{
    color: #212121;
}
.product-description .might-like-image
{
   
    background: #ededed;
    height: 200px;
    width: 100%;
    padding: 5px;
}
.product-description .might-like-image img
{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.product-description .might-like-wrapper .might-like-text
{
    display: flex;
    justify-content: space-between;
}
.product-description .might-like-wrapper .might-like-text .texts
{
    font-family: 'Playfair Display', serif;
   font-weight: 400;
   font-size: 16px;
   letter-spacing: 1px;
   text-transform: capitalize;
}
.product-description .might-like-wrapper .add-button button
{
    border: none;
    background: none;
    line-height: 42px;
}
.product-description .might-like-wrapper .add-button .btn-icon
{
    color: #78BE20;
    font-weight: 400;
    font-size: 32px;
}

.product-description .might-like-wrapper 
{
    position: relative;
    cursor: pointer;
}
.product-description .might-like-wrapper .card-button button
{
    position: absolute;
    top: 0;
    right: -14px;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 14px;
}

/* image-modal-style */

.guest-image-style
{
    left: 50% !important;
    top: 70px !important;
    padding: 0 !important;
    min-width: 590px !important;
}

.guest-image-style img
{
    height: 550px !important;
    object-fit: contain !important;
    width: 100% !important;
    padding: 0 20px;
}

/* Modal for guest user */

.showModal1 .guest-user-modal
{
    text-align: center;
    padding: 10px 0;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    border-radius: 10px;
    position: fixed;
    top: 100px;
    left: 50%;
    z-index: 18;
    width: 400px;
    margin: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.showModal1 .guest-user-modal .modal-btn
{
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}
.showModal1 .guest-user-modal .modal-btn .btn
{
    background: #6BA539;
    font-weight: 500;
    color: #f2f2f2;
    text-transform: uppercase;
}
.showModal1 .guest-user-modal img
{
    height: 160px;
    object-fit: contain;
}
.showModal1 .guest-user-modal p
{
    font-size: 18px;
    font-weight: 400;
}
.showModal1 .guest-user-modal .close-btn
{
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 27px;
    color: #706f6f;
    cursor: pointer;
}
.react-multi-carousel-item
    {
    margin: 0 0px;
    }

.btn-icon
{
    font-size: 16px;
}
/* tab button */

.product-description .tab-btn-ui
{
    border: none;
    background: none;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #212121;
    padding: 0;
    font-family:'Playfair Display', serif;
}


/* responsive */

@media (hover :none) { 
    .product-description .product-details .wishlist-btn svg:hover {
        color: inherit;
        cursor: pointer;
    }
}

@media only screen and (max-width:1200px) {
    .guest-image-style img
    {
        height: 430px!important;
    }
} 

@media only screen and (max-width:1024px) {
}

@media only screen and (max-width:991px) {
    .product-description .single-image-detail .sold-out-image
    {
        right: 10%;
    }
}

@media only screen and (max-width:767px) {
   
.add-to-bag-animated
{
    top: 12%;
}
.product-description .single-image-detail .sold-out-image
{
    right: 24%;
}
    .product-description .product-details .price-wrap
    {
        font-size: 17px;
    }
    .product-description .product-details .title-wrap
    {
        padding: 0;
        margin: 0;
    }
    .product-description .desktop-view-image
    {
    display: none;
    }
.product-description .mobile-view-image
    {
    display: block;
    }
    
    .product-description .product-details
{
    margin-left: 0;
    margin-top: 0;
}
}

@media only screen and (max-width:575px) {
    .product-description .single-image-detail .sold-out-image
    {
        right: 15%;
    }
    .product-description .product-details .price-wrap
    {
        font-size: 16px;
    }
    .product-description .product-details .product-detail-box-1
    {
        padding: 0;
    }
    .product-description .product-details .title-wrap
    {
        padding: 0;
    }
    .guest-image-style
    {
        display: none !important;
    }
    .product-description .tabs-section .tab-content .tab-1 p {
        /* font-size: 14px; */
        line-height: 21px;
    }


}

@media only screen and (max-width:480px) {
    .product-description .tabs-section .tab-content .tab-1 {
        border: 1px solid #D8D7DF;
        padding: 8px;
        line-height: 15px;
        color: #848684;
        font-size: 10px;
    }
    .product-description .single-image-detail .sold-out-image
    {
        right: 9%;
    }
    .product-description .product-details .title-wrap
    {
        font-size: 17px !important;
        margin: 0;
        padding: 0;
    }
    .product-description .multiple-images
    {
        padding: 10px 0;
    }
    .product-description .product-details .product-detail-box-1
    {
        padding: 5px 0;
    }
    .product-description .product-details .price-wrap
    {
        font-size: 14px;
    }
    .product-description .multiple-images .multiple-image-1 img
    {
        height: 85px;
    }
    .product-description .might-like-image
    {
        height: 190px;
    }
    .product-description .tabs-section .tab-content .tab-1 p{
        /* text-align: justify; */
    }
    .product-description .product-details .price-wrapper-3 li.common-para-3 {

        font-size: 14px;
    }
}

@media only screen and (max-width:430px) {
    .product-description .multiple-images .multiple-image-1 img
    {
        height: 70px;
    }
    .product-description .single-image-detail .sold-out-image
    {
        right: 2%;
    }
}

@media only screen and (max-width:391px) {
    .product-description .single-image-detail .sold-out-image
    {
        right: 0;
    }
}

@media only screen and (max-width:320px) {
    .product-description .multiple-images .multiple-image-1 img
    {
        height: 60px;
    }
    .product-description .product-details .title-wrap
    {
        font-size: 15px;
        margin: 0;
    }
    .product-description .product-details .wishlist-btn
    {
        font-size: 20px;
    }
    .product-description .product-details .price-wrap
    {
        font-size: 13px;
    }
    .product-description .product-details .product-detail-box-1
    {
        padding: 0;
    }
}
/* wishlist button */

.unavailable-image{
    height: 60px!important;
}
.cart-section .cart-left-wrap .wishlist-close-icons-wrapper
{
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0;
}
.cart-section .cart-left-wrap .wishlist-btn {
    border: none;
    color: #D8D7DF;
    padding: 0;
    cursor: pointer;
    font-size: 26px;
    background: none;
    transition: 0.6s all;
}
.cart-section .cart-left-wrap .wishlist-btn svg:hover {
    color: #B86097;
    cursor: pointer;
}
.wishlisted-btn  {
    color: #B86097 !important;
}
.not-wishlist-btn {
    color: #D8D7DF;
}
.cart-section .cart-left-wrap .quantity-input input
{
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.cart-section .cart-left-wrap .quantity-button button{
    border: 0;
    background: #fff;
    width: 80%;
    padding: 5px 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.cart-section .cart-left-wrap .quantity-button button svg{
    font-size:10px;
}

.cart-section 
{
    padding: 30px 0;
}
.cart-section .cart-left-wrap
{
    border-bottom: 1px solid #EAEAEC;
    margin: 15px;
    position: relative;
    height: 200px;
}
.cart-section .cart-left-wrapper .cart-left-image img
{
    position: relative;
    height: 170px;
    width: 170px;
    object-fit: cover;
    background: #2121;
    padding: 5px;
    cursor: pointer;
}

.cart-section .cart-left-wrap .cart-right-image-text
{
    line-height: 31px;
}
.cart-section .cart-left-wrap .cart-trash-icon
{
    padding: 0;
    margin-left: 10px;
    cursor: pointer;
    font-size: 25px;
    border: none;
    color: #706f6f;
    background: none;
    transition: 0.6s all;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-heading
{
    font-weight: 600;
    width: 82%;
    text-transform: capitalize;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-para
{
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #444444;
    margin: 0;
    line-height: 1.5;
}
.cart-para::first-line
{
    text-transform: capitalize;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-para-base
{
    font-size: 13px;
    color: #94969f;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-quantity
{
    font-weight: 600;
    background: #f5f5f6;
    padding: 6px 10px;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-price
{
    font-weight: 600;
}

.cart-section .cart-right-wrapper
{
    padding: 20px;
    border: 1px solid #E4E1D9;
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
}
.cart-section .cart-right-wrap .cart-price-detail
{
    font-size: 15px;
    font-weight: bold;
}
.cart-section .cart-right-wrap .cart-total-price , .cart-section .cart-right-wrap .cart-discount-price , .cart-section .cart-right-wrap .cart-delivery-price
{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}
.cart-section .cart-right-wrap .cart-total-amount
{
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E4E1D9;
    padding: 15px 0;
}
.cart-section .cart-right-wrap .cart-order-btn
{
    width: 100%;
    padding: 10px 20px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background: #495057;
}
.cart-section .cart-right-wrap .continue-shop-btn
{
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #495057 !important;
    text-transform: uppercase;
    font-weight: 600;
    color: #495057 !important;
    background: #fff !important;
    font-size: 14px;
}
.cart-section .cart-right-wrap .continue-shop-btn:hover {
   
   color: #fff !important;
   background: #495057 !important;

}
.cart-section .cart-right-wrap .cart-order-btn:hover
{
    background: #495057;
}


/* Empty cart */

.cart-section .wishlist-card {
    justify-content: center;
    text-align: center;
    padding: 80px 0;
    
}
.cart-section .wishlist-card button {
    color : #fff;
    padding: 10px 30px;
    border: none;
    background: #4D4D4D;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 18px;
}
.cart-section .wishlist-card .wishlist-empty-icon img
{
    height: 105px;
    width: 90px;
    margin: 20px 0;
}
.cart-section .wishlist-card-text
{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
}
.cart-section .wishlist-card-text span{
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 24px;
    font-family: 'Lato', sans-serif;
}

.cart-section .wishlist-para
{
    color: #95989F;
}
.sold-out
{
    background-color: #ed1b24;
    padding: 4px 15px;
    font-size: 10px;
    color:#fff;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    line-height: 1.5;
    font-style: italic;
    border-radius: 4px;
    align-items: center;
    border: 0;
    border: 1px solid #ed1b24;
    justify-content: center;
}

/* --------tooltip remove css------- */
.__react_component_tooltip.show{
    display: none!important;
}




@media (hover:none) {
    .cart-section .cart-left-wrap .wishlist-btn svg:hover {
        color: inherit;
    }
}


/* responsive */

@media only screen and (max-width:991px){
    .cart-section .cart-left-wrap .cart-right-image-text .cart-heading
    {
        width: 70%;
        line-height: 1.3;
    }
}

@media only screen and (max-width:890px) {
    .cart-section .cart-right-wrap .cart-order-btn
{
    font-size: 14px;
}
}

@media only screen and (max-width:818px) {
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 12px;
    }
}

@media only screen and (max-width:767px){
    .cart-section .wish-trash-icon-color
{
    display: flex;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #a78b9c;
}
.cart-section .cart-left-wrap .wishlist-btn,.cart-section .cart-left-wrap .cart-trash-icon{
    font-size:21px;
}
    .cart-section .cart-left-wrap .wishlist-close-icons-wrapper
    {
        top: 10px;
        right: 0px;
    }
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 300px;
        width: 100%;
        object-fit: contain;
    }
    .cart-section .cart-left-wrap .change-in-padding
    {
        padding: 0;
    }
    .cart-section .cart-left-wrap .cart-trash-icon
    {
        top: 10px;
        right: 2px;
    }
    .cart-section .cart-left-wrap
    {
        height: 450px;
    }
    .cart-section .cart-left-wrap .cart-right-image-text .cart-heading
    {
        width: 100%;
        line-height: unset;
    }
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 15px;
    }
}

@media only screen and (max-width:575px){
    
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 15px;
    }
    .cart-section .wishlist-card
    {
        padding: 20px 0;
    }
    
    .cart-section .cart-left-wrap .wishlist-close-icons-wrapper {
        top: 8px;
        right: 0;
    }
}

@media only screen and (max-width:480px){
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 252px;
    }
   
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 14px;
    }
    .cart-section .cart-left-wrap
    {
        height: auto;
    }
}

@media only screen and (max-width:430px){
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 227px;
    }
    .cart-section .cart-left-wrap
    {
        height: auto;
    }
}

@media only screen and (max-width:355px){
    .cart-section .cart-left-wrap{
        height: auto;
    }
    
}

@media only screen and (max-width:354px){
    .cart-section .cart-left-wrap .cart-right-image-text
    {
        line-height: 24px;
    }
}

@media only screen and (max-width:320px){
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 180px;
    }
    .cart-section .cart-right-wrap .cart-order-btn {
        padding: 8px 15px;
        font-size: 12px;
    }
    .cart-section .cart-left-wrap
    {
        height: auto;
    }
}
/* Global Css for page */

.cat-wrapper
{
    padding: 5px 0;
}
.cat-wrapper aside span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.cat-wrapper .sidebar-box h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight : 700;
    font-size : 18px;
    line-height: 21.78px;
    padding: 30px 15px;
}

.cat-wrapper  aside li p {
    padding-left: 15px;
}

.cat-wrapper .sidebar-box-content span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight : 600;
    font-size : 15px;
    line-height: 100%;
}
/* Page Border */

.cat-wrapper .prod-tag h2
{
    color: #FF585D;
    font-size: 21px;
    font-weight: 600;
    border-bottom: 1px solid #dfdada;
    padding-bottom: 15px;
}
.cat-wrapper .bred-crumb-one{

    color: #777;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
}
.cat-wrapper .separator{
    margin: 0 5px;
}
   .cat-wrapper .bred-crumb-two
   {

    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
   }
   .cat-wrapper hr{
    border-top: 1px solid #e6dbdb;
   }




.sidebar-box {
    
    border: 1px solid #B3B3B3;
    /* border-top: 0 !important; */
    position: -webkit-sticky;
    position: sticky;
    top: 130px;
    cursor: pointer;
}
.sidebar-box aside li:hover
{
    color: #FF585D;
}
.sidebar-box aside li.active
{
    color: #FF585D;
    font-weight: 600;
}

/* Sidebar */
.sidebar {
    z-index: 0;
    min-height: 100vh;
    top: 0;
}

/* Categories Image and heading */

.cat-head {
position: relative;
}

.cat-head .cat-head-content {
    position: absolute;
    right: 2%;
    top: 50%;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
}

.cat-head .cat-head-content h1 {
    color: #FF585D;
    font-weight: 700;
}

/* Product Css */

.cat-wrapper .product-image img
{
    width: 100%;
    min-height: 210px;
    height: 246px;
    object-fit: contain;
    background: #ededed;
    padding: 5px;
}
.cat-wrapper .product-image
{
    margin-bottom: 10px;
    position: relative;
}
.cat-wrapper .product-image .product-image-wish-list button
{
    position: absolute;
    top: 1px;
    right: 0;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#6BA539;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 13px;
}
.cat-wrapper .product-image:hover button
{
    opacity: 1;
    visibility: visible;
}
.cat-wrapper .product-image .image-fit
{
    object-fit: contain;
}
.cat-wrapper .product-name 
{
    display: flex;
    justify-content: space-between;
}
.cat-wrapper .product-name .product-name-size
{
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.7px;
   font-family: 'Lato', sans-serif;
    color: #202020;
 
    text-align: center;
    
}

.wishlist-icon:hover
{
    cursor: pointer;
}
 .wishlist-icon:hover button
{
    visibility: visible;
    opacity: 1;
}
 .product-name
{
    display: flex;
    justify-content: space-between;
}
.product-name .texts
{
   font-weight: 500;
   font-size: 16px;
}
 .card-button button
{
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 15px;
}
.wishlist-fs
{
    font-size: 25px;
}
 .Wishlist-hover-icon {
    color: red;
}




/* media query */

@media only screen and (max-width : 1920px)
{
    .cat-wrapper .product-image img
    {
        min-height: 220px;
    }
    .cat-wrapper .product-image img
{
    
    height: 246px;
    object-fit: contain;
}
}

@media only screen and (max-width : 1399px)
{
    
}
@media only screen and (max-width : 1199px)
{

    .cat-head .cat-head-content {
        right: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        padding: 0 10px;
        font-size: 13px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 25px;
    }
    .cat-wrapper .sidebar-box h4
    {
        font-size: 21px;
    }
    .cat-wrapper .product-name .product-name-size
    {
        font-size: 14px;
    }
    .cat-wrapper .product-name .product-name-sizes
    {
        font-size: 14px;
    }
    .cat-wrapper .product-image img {
        min-height: 210px;
    }
    .wishlist-fs {
        font-size: 24px;
    }
}
@media only screen and (max-width : 1024px)
{
    .cat-head .cat-head-content
    {
        font-size: 12px;
    }
    .cat-wrapper .prod-tag h2
    {
        font-size: 19px;
    }
    .cat-wrapper .sidebar-box h4
    {
        font-size: 19px;
    }
    .cat-head .cat-head-content h1
    {
        font-size: 19px;
    }
    .cat-wrapper aside span
    {
        font-size: 13px;
    }
    .cat-wrapper .product-image img {
        min-height: 180px;
    }
    .cat-wrapper .product-name .product-name-size {
    font-size: 13px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 13px;
        }
        .wishlist-fs {
            font-size: 21px;
        }
}
@media only screen and (max-width : 991px)
{
    .cat-head .cat-head-content {
        font-size: 10px;
        top: 60%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 17px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .cat-wrapper .product-image img {
        min-height: 160px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 12px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 12px;
    }
    .wishlist-fs {
        font-size: 20px;
    }
}
@media only screen and (max-width : 767px)
{
    .cat-wrapper .product-image img {
        min-height: 190px;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 16px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 16px;
        margin-bottom: 0;
    }
    .cat-head .cat-head-content h1 {
        font-size: 16px;
    }
    .cat-wrapper aside span {
        font-size: 12px;
    }
    .wishlist-fs {
        font-size: 19px;
    }
    .cat-head .cat-head-content {
        font-size: 6px;
        top: 65%;
    }
}
@media only screen and (max-width : 575px)
{
    .cat-head .cat-head-content {
        top: 70%;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 14px;
        padding: 20px 15px;
    }
    .cat-wrapper .cat-box-border {
        border-top: none;
    }
}
@media only screen and (max-width : 520px)
{
    .cat-wrapper .product-image img
    {
        padding: 0!important;
        background: transparent!important;
        min-height: 180px!important;
        height: 235px!important;
    }
}
@media only screen and (max-width : 480px)
{
    .cat-wrapper .prod-tag h2 {
        font-size: 14px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 14px;
    }
    .cat-wrapper aside span {
        font-size: 11px;
    }
    .cat-wrapper .product-image img {
        min-height: 160px!important;
        height: 200px!important;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 11px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 11px;
    }
    .wishlist-fs {
        font-size: 17px;
    }
    
}
@media only screen and (max-width : 430px)
{
    .cat-wrapper .sidebar-box h4 {
        font-size: 13px;
        padding: 15px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 12px;
    }
    .cat-head .cat-head-content {
        font-size: 5px;
        top: 72%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 13px;
        margin: 0;
    }
    /* .cat-wrapper .product-image img {
        min-height: 140px;
        padding: 5px;
    } */
    .wishlist-fs {
        font-size: 14px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 10px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 10px;
    }
    .cat-wrapper .product-image img {
        min-height: 160px!important;
        height: 190px!important;
    }
}

@media only screen and (max-width : 380px)
{
    .cat-wrapper .product-image img {
        min-height: 140px!important;
        height: 160px!important;
    }
}

@media only screen and (max-width : 320px)
{
    .cat-wrapper .product-image img {
        min-height: 120px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 11px;
    }
    .cat-wrapper aside span {
        font-size: 10px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 10px;
    }
    .cat-head .cat-head-content {
        font-size: 4px;
        top: 82%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 11px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 9px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 9px;
    }
    
    .cat-wrapper .product-image img {
        min-height: 160px!important;
        height: 180px!important;
    }
}
.coming-soon .no-product-image
{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
}
.coming-soon .no-product-image img
{
    height: 150px;
    width: 100%;
    object-fit: contain;
}
.coming-soon .no-product-image .product-coming
{
    font-family:'Playfair Display', serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0.50px;
    padding-top: 30px;
    color: #5a5a5a;
}

.about-page
{
    padding: 60px 0;
}

.about-section-2
{
    background: #F7F7F7;
    padding: 60px 0 80px 0;
}
.about-section-2 .about-section-2-image img
{
    height: 250px;
    width: 100%;
    object-fit: cover;
}

.about-section-3
{
    padding: 60px 0;
}
.about-section-3 .our-team-right-carousel .p-carousel-container
{
    background: none;
}
.about-section-3 .our-team-right-carousel .product-item-1 .product-item-content-1
{
    background: none;
    box-shadow: none;
    padding: 15px 0;
    margin: 0;
}
.about-section-3 .our-team-right-carousel .product-item-1 .product-image-1
{
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.about-section-4
{
    padding: 80px 0;
}
.about-section-4 .our-vision-left-image img
{
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.about-section-5
{
    padding: 60px 0;
}
.about-section-5 .easy-payment-right-image img
{
    height: 500px;
    width: 100%;
    object-fit: cover;
}
.about-section-6
{
    padding: 60px 0;
    background: #F7F7F7;
}
.about-page .about-right-text
{
    padding-left: 40px;
}
.our-team-right-carousel
{
    padding-left: 40px;
}
.p-carousel-container
{
    background: #F7F7F7;
}
.product-item-1
{
    display: flex;
    align-items: center;
}
.product-item-1 .product-item-content-1
{
    background: #fff;
    position: relative;
    padding: 5px;
    margin: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 360px;
}
.product-item-1 .product-image-1
{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}
.product-item-1 .colour-change
{
    color: #41748D;
    padding-top: 15px;
    font-family: 'Lato', sans-serif;
    height: calc(100% - 165px);
    overflow: auto;
}
.product-item-1 .colour-change::-webkit-scrollbar {
    display:none;
  }

.product-item-1 .colour-change-1
{
    color: #757575;
    padding-top: 6px;
    font-family: 'Playfair Display', serif;
     position: absolute;
     left:50%;
     -webkit-transform: translateX(-50%);
             transform: translateX(-50%);
     bottom: 20px;

}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    
    border: none;
    color: #343a40;
    background: #e9ecef;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
    width: 30px !important;
    height: 30px !important;
    z-index: 4 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    position: relative;
    
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
   
    border-color: transparent;
    
}
.p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus
{
    box-shadow: none;
}
/* ------------iphone------------------------- */


.p-carousel .p-carousel-content .p-carousel-next .pi-chevron-right:before
{
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #343A4F;
    border-left: 2px solid #343A4F;
    -webkit-transform: rotate(134deg);
            transform: rotate(134deg);
    position: relative;
    right: 2px;
    bottom: 1px;
    
}

.p-carousel .p-carousel-content .p-carousel-prev .pi-chevron-left:before
{
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #343A4F;
    border-left: 2px solid #343A4F;
    -webkit-transform: rotate(-48deg);
            transform: rotate(-48deg);
    position: relative;
    left: 2px;
    bottom: 1px;
}


/* --------------iphone end--------------- */


/* responsive */

@media only screen and (max-width:767px) {
.about-page
{
    padding: 30px 0;
}
.about-page .about-left-image img
{
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.about-page .about-right-text
{
    padding-left: 0;
}
.common-heading-2
{
    margin: 0;
}
.about-section-2
{
    padding-top: 30px;
}
.about-section-3
{
    padding: 30px 0;
}
.our-team-left-text
{
    padding-bottom: 30px;
}
.about-section-4
{
    padding: 30px 0;
}
.about-section-4 .our-vision-left-image img
{
    height: 300px;
}
.about-section-5
{
    padding: 0;
}
.about-section-5 .easy-payment-right-image img
{
    padding: 30px 0;
    height: 300px;
}
.about-section-6
{
    padding: 30px 0;
}
}




@media only screen and (max-width:575px) {
    .our-team-right-carousel{
        padding-left: 0;
    }
}


@media only screen and (max-width:430px) 
 {
    .about-page .about-left-image img
{
    height: 210px;
}
.common-heading-2
{
    font-size: 19px;
}
.about-section-2
{
    padding-top: 0;
}
 }

.contact-us-page .error-message{
    color: red;
}
.contact-us-page
{
    padding: 50px 0;
}
.contact-us-page .discover-wrapper h2
{
    color: #3c3333d1;
    font-size: 30px;
    font-weight: 500;
    font-family:'Playfair Display', serif;
}
.contact-us-page .discover-wrapper p
{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #777;
}
.contact-us-page .visit-us-wrapper h3
{
    color: #3c3333d1;
    font-size: 30px;
    font-weight: 500;
    font-family:'Playfair Display', serif;;
}
.contact-us-page .visit-us-wrapper p
{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #777;
}
.contact-us-page .email-wrapper h4
{
    color: #3c3333d1;
    font-size: 30px;
    font-weight: 500;
    font-family:'Playfair Display', serif;;
}
.contact-us-page .email-wrapper p
{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #777;
}
.contact-us-page .callus-wrapper h5
{
    color: #3c3333d1;
    font-size: 30px;
    font-weight: 500;
    font-family:'Playfair Display', serif;;
}
.contact-us-page .callus-wrapper p
{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #777;
}

.contact-us-page .form-wrap-left h6
{
    color: #3c3333d1;
    font-size: 30px;
    font-weight: 500;
    font-family:'Playfair Display', serif;;
}
.contact-us-page .form-wrap-left p
{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #777;
}
.contact-us-page .form-wrap-right input
{
    border: none;
    background: none;
    border-bottom: 1px solid #B3B3B3;
    width: 100%;
}


 .contact-us-page .form-wrap-right input::-webkit-input-placeholder
 {
    font-size: 13px;
 }


 .contact-us-page .form-wrap-right input::placeholder
 {
    font-size: 13px;
 }
input:focus-visible {
    outline: none;
    border-bottom: 1px solid #000!important;
    opacity: .8!important;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)!important; */
}
.contact-us-page .form-btn button
{
    background: #78BE20;
    color: #212121;
    border: none;
    padding: 6px 30px;
    margin-top: 40px;
}
.contact-us-page .contact-heading {
    font-family: 'Lato', sans-serif;
    font-size: 16px!important;
    font-weight: 700!important;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #212529!important;
}

.contact-success-msg{
    height: 22px;
    overflow: hidden;
}


@media only screen and (max-width : 767px) {
    .contact-us-page .form-wrap-left
    {
        padding: 0 !important;
    }
    .contact-us-page .removed
    {
        padding: 0 !important;
    }
    .contact-us-page .discover-wrapper h2
    {
        font-size: 30px;
    }
    .contact-us-page .discover-wrapper p {
        padding: 6px 0;
    }
    .contact-us-page .email-wrapper p
    {
        padding: 0;
    }
    .contact-us-page .visit-us-wrapper h3 ,.contact-us-page .email-wrapper h4, .contact-us-page .callus-wrapper h5
    {
        font-size: 25px;
    }
    .contact-us-page .form-wrap-left h6
    {
        font-size: 30px;
    }
    .contact-us-page .form-wrap-left p
    {
        padding: 8px 0;
    }
}

@media only screen and (max-width : 575px) {
    .contact-us-page
    {
        padding: 25px 0;
    }
}

@media only screen and (max-width : 480px) {
    .contact-us-page .discover-wrapper h2 ,.contact-us-page .form-wrap-left h6
    {
        font-size: 23px;
    }
    .contact-us-page .discover-wrapper p , .contact-us-page .visit-us-wrapper p , .contact-us-page .visit-us-wrapper .para-2 , .contact-us-page .form-wrap-left p , .contact-us-page .email-wrapper p , .contact-us-page .callus-wrapper p
    {
        font-size: 14px;
    }
    .contact-us-page .visit-us-wrapper h3, .contact-us-page .email-wrapper h4, .contact-us-page .callus-wrapper h5
    {
        font-size: 20px;
    }
    .contact-us-page .removed-1
    {
        padding: 20px 0 !important;
    }
    .contact-us-page .form-wrap-right input::-webkit-input-placeholder
    {
        font-size: 12px;
    }
    .contact-us-page .form-wrap-right input::placeholder
    {
        font-size: 12px;
    }
}
@media only screen and (max-width : 320px) {
    .contact-us-page .discover-wrapper h2, .contact-us-page .form-wrap-left h6
    {
        font-size: 18px;
    }
    .contact-us-page .discover-wrapper p, .contact-us-page .visit-us-wrapper p, .contact-us-page .visit-us-wrapper .para-2, .contact-us-page .form-wrap-left p, .contact-us-page .email-wrapper p, .contact-us-page .callus-wrapper p
    {
        font-size: 12px;
        margin-bottom: 10px;
    }
}
.error-message{
    color: red;
}
.form-control:disabled{
    background-color: #fff!important;
}
.profile-wrapper .profile-section {
    margin-top: 16px;
}

.profile-wrapper .profile-section label {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .7px;
    font-family: 'Lato', sans-serif;
}

.profile-wrapper .profile-section input:focus {
    border: 1px solid #000!important;
    opacity: .8!important;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)!important;
}




.profile-wrapper .profile-section div input:focus, div textarea:focus {
    outline: none;
    box-shadow: none;
    border:2px solid #f1cdd7;

}
.profile-wrapper .profile-section  div textarea:focus {
    outline: none;
    box-shadow: none;
    border:2px solid #f1cdd7;

}
.profile-wrapper .profile-section .profile-submit-btn button {
    border: none;
    color: #ffffff;
    background-color: #495057;
    outline: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 40px;
    width: auto;
    margin: 25px 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
}
.form-control
{
    border-radius: 0 !important;
}

.profile-wrapper .profile-section .profile-submit-btn button:hover {
    background-color: #495057;
    color: #ffffff;
}

.profile-wrapper .profile-section h4 {
    padding : 18px 0;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 400;
    font-size: 20px;
    font-family:'Playfair Display', serif;;
    text-transform: capitalize;
}
.wishlist-wrapper
{
    padding: 50px 0;
}
.wishlist-wrapper .wishlist-card {
    justify-content: center;
    text-align: center;
    padding: 80px 0;
    
}
.wishlist-wrapper .wishlist-card button {
    color : #fff;
    padding: 10px 30px;
    border: none;
    background: #4D4D4D;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 18px;
}
.wishlist-wrapper .wishlist-card .wishlist-empty-icon img
{
    height: 105px;
    width: 90px;
    margin: 20px 0;
}
.wishlist-wrapper .wishlist-card-text 
{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
}
.wishlist-wrapper .wishlist-card-text  span{
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 24px;
    font-family: 'Lato', sans-serif;
}
.wishlist-wrapper .wishlist-para
{
    color: #95989F;
}

.wishlist-wrapper .product-image img
{
    width: 100%;
    min-height: 250px;
    height: 106px;
    object-fit: cover;
    background: #ededed;
    padding: 5px;
    cursor: pointer;
}
.wishlist-wrapper .product-image
{
    margin-bottom: 10px;
    position: relative;
}
.wishlist-wrapper .product-image-wish-list button
{
    position: absolute;
    top: 1px;
    right: 0;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#6BA539;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 13px;
}

.wishlist-wrapper .product-image:hover button
{
    opacity: 1;
    visibility: visible;
}
.wishlist-wrapper .product-name 
{
    /* display: flex; */
    /* justify-content: space-between; */
}
.wishlist-wrapper .product-name .product-name-size
{
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}
.wishlist-wrapper .product-name .add-button button
{
    border: none;
    color: #78BE20;
    font-weight: 400;
    font-size: 35px;
    background: none;
}
.wishlist-wrapper .wishlist-wrap-box
{
    position: relative;
}
.wishlist-wrapper .wishlist-trash-icon
{
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    font-size: 26px;
    border: none;
    color:#706f6f;
    background: none;
    transition: 0.6s all;
}
.wishlist-wrapper .wishlist-trash-icon:hover
{
    color: #706f6f;
    transition: 0.6s all;
}
.wishlist-wrapper .continue-shopping-btn{
    display: flex;
    justify-content: center;
    margin-top: 2px;
    
}
.wishlist-wrapper .continue-shopping-btn button {
    color: #fff;
    padding: 10px 30px;
    border: none;
    background: #4d4d4d;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 14px;
}



.wishlist-cancil {
    position: absolute;
    color: #fff;
    font-size: 23px;
}

/* responsive */

@media only screen and (max-width : 575px) {
    .wishlist-wrapper
    {
        padding: 25px 0;
    }
    .wishlist-wrapper .wishlist-card
    {
        padding: 20px 0;
    }
}
@media only screen and (max-width : 430px) {
    .wishlist-wrapper .product-image img
    {
        min-height: 210px;
    }
}
@media only screen and (max-width : 320px) {
    .wishlist-wrapper .product-image img
    {
        min-height: 170px;
    }
    .wishlist-wrapper .product-name .product-name-size
    {
        font-size: 12px;
    }
}
.checkout-page
{
    padding-bottom: 60px;
}

.checkout-page .add-address
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    flex-direction: column;
    border-style: dashed;
    height: 250px;
    width: 100%;
    border-width: 2px;
    box-sizing: border-box;
    border-color: #C7C7C7;
    text-align: center;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.checkout-page .add-address svg
{
    font-size: 32px;
    color: #d9d8d8;
    cursor: pointer;
}
.checkout-page .add-address p
{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: #424242de;
    padding-top: 15px;
    letter-spacing: 1px;
}
/* address css */

.checkout-page .tab-content
{
    padding: 15px 0;
}
.checkout-page .nav-link
{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
}
.checkout-page .form-check-input:checked
{
    background-color: #495057;
    border-color: transparent;
}
.checkout-page .save-address {
    height: 250px;
    width: 100%;
    border-width: 1px;
    border-radius: 8px;
    box-sizing: border-box;
    border-color: #C7C7C7;
    box-shadow: 0 3px 1px 0 rgb(0 0 0 / 10%);
    border-style: solid;
    margin-bottom: 20px;
}

.checkout-page .default-add {
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}
.checkout-page .default-address
{
    font-size: 14px;
    color: #777;
    font-weight: 400;
    letter-spacing: 0.25px;
    font-family: 'Lato', sans-serif;
}
.checkout-page .address-logo
{
    font-size: 14px;
    color: #777;
    font-weight: 400;
    letter-spacing: 0.25px;
    font-family: 'Lato', sans-serif;
    padding-left: 5px;
}
.checkout-page .full-address
{
    padding: 10px;
}
.checkout-page .full-address .full-add-name
{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.checkout-page .full-address .full-address-1
{
    font-size: 13px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    margin-bottom: 2px;
    color: #777;
}
.checkout-page .full-address .full-add-phn
{
    font-size: 13px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    margin: 0;
    color: #777;
}
.checkout-page .full-address .full-editing-of-add
{
    padding: 20px 0;
    display: flex;
}
.checkout-page .full-address .full-editing-of-add .full-add-edit , 
.checkout-page .full-address .full-editing-of-add .full-add-remove
{
    text-decoration: none;
    color: #424242db;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
}
.checkout-page .full-address .full-editing-of-add .full-add-edit:hover , 
.checkout-page .full-address .full-editing-of-add .full-add-remove:hover
{
    color: #b86097;
}

/* commonted on 4 sept to add focus on state field */
/* .checkout-page .form-select:focus{
    border-color:#D8D7DF;
    opacity: .6;
} */
/* address css ends */


.exist-address .nav-tabs button
{
    padding: 5px 15px !important;
}

.add-checkbox {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    
}
.add-checkbox input {
    width: 0 !important;
    float: right !important;
}
.checkout-page .right-side-details
{
    position: -webkit-sticky;
    position: sticky;
    top: 210px;
}
.checkout-page .right-side-details .order-summary-wrapper
{
    padding: 20px;
    border: 1px solid #E4E1D9;
}
.checkout-page .right-side-details .order-price-detail
{
    font-size: 15px;
    font-weight: bold;
}
.checkout-page .right-side-details .order-total-price , .checkout-page .right-side-details .order-delivery-price
{
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}
.checkout-page .right-side-details .order-total-amount
{
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E4E1D9;
    padding: 15px 0;
}
.checkout-page .right-side-details .order-summary-btn
{
    width: 100%;
    padding: 10px 20px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background: #495057;
}
.checkout-page .right-side-details .order-summary-btn:hover
{
    background: #495057;
}

.checkout-page h1,.checkout-page h2,.checkout-page h3,.checkout-page h4
{
    font-size: 16px;
    font-weight: 600;
    padding-top: 25px;
    padding-bottom: 10px;
    font-family: playfair;
}
.checkout-page input
{
    border: 1px solid #D8D7DF;
    padding: 7px;
    width: 100%;
}
.checkout-country-state{
    border-radius: 0!important;
}
.checkout-country-state:focus{
    border: 1px solid #000!important;
    opacity: .8;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)!important;
}
.checkout-page input:focus{
    border: 1px solid #000;
    opacity: .8;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] 
{
    -moz-appearance: textfield;
}
.checkout-page label
{
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .7px;
    font-family: 'Lato', sans-serif;
}
.checkout-page .profile-wrapper .profile-section div input:focus, div textarea:focus
{
    border: 1px solid #D8D7DF !important;
}
.checkout-page textarea
{
    border: 1px solid #D8D7DF;
    width: 100%;
}
.checkout-page .card
{
    margin: 15px 0;
    cursor: pointer;
    border: none;
}

.checkout-page .address-selection
{
    position: relative;
}
.checkout-page .address-selection-choice
{
    position: absolute;
    top: 0;
    right: 0;
}

/* Modal for guest user */

.showModal1 .guest-user-modal
{
    text-align: center;
    padding: 10px 0;
    background: #f2f2f2;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    border-radius: 10px;
    position: fixed;
    top: 100px;
    left: 50%;
    z-index: 18;
    width: 400px;
    margin: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.showModal1 .guest-user-modal .modal-btn
{
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}
.showModal1 .guest-user-modal .modal-btn .btn
{
    background: #495057;
    font-weight: 500;
    color: #f2f2f2;
    text-transform: uppercase;
}
.showModal1 .guest-user-modal img
{
    height: 160px;
    object-fit: contain;
}
.showModal1 .guest-user-modal p
{
    font-size: 18px;
    font-weight: 400;
}
.showModal1 .guest-user-modal .close-btn
{
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 27px;
    color: #706f6f;
    cursor: pointer;
}

#myModal .guest-user-modal{
    padding: 15px;
} 
#myModal .guest-user-modal img{
    height:100px;
}
#myModal .guest-user-modal p{
    font-size: 14px;
    font-weight: 500;
}





/* responsive */

@media only screen and (max-width:818px) {
    .checkout-page .address-logo
    {
        font-size: 12px;
    }
    .checkout-page .default-address
    {
        font-size: 13px;
    }
    .checkout-page .default-add
    {
        padding: 4px 10px;
    }
    .checkout-page .adress-boxes-2
    {
    display: block;
    }
}

@media only screen and (max-width:767px)
{
    .checkout-page .default-address
    {
        font-size: 14px;
    }
    .checkout-page .default-add
    {
        padding: 5px 10px;
    }
    .checkout-page .left-side-details .left-checkout , .checkout-page .left-side-details .right-checkout
{
    padding: 0;
}
.checkout-page .left-side-details h2
{
    padding: 20px 0;
    font-size: 20px;
}
.checkout-page .right-side-details .order-summary-btn
{
    font-size: 15px;
}
.checkout-page .right-side-details .order-summary-wrapper
{
    margin-top: 30px;
}
}
@media only screen and (max-width:637px) {
    .checkout-page .default-add
    {
        padding: 4px 10px;
    }
}
@media only screen and (max-width:575px) {
    .checkout-page .default-add
    {
        padding: 3px 10px;
    }
}
@media only screen and (max-width:480px) {
    .checkout-page .default-add
    {
        padding: 2px 10px;
    }
    .checkout-page .save-address
    {
        height: 190px;
    }
    .manage-checkout-address{
        max-height: 120px!important;
    }
    
    .manage-checkout-address::-webkit-scrollbar {
        width: 4px;
    }

    .checkout-page .full-address .full-editing-of-add
    {
        padding: 10px 0;
    }
    .checkout-page .full-address .full-add-name
    {
        padding-top: 0;
    }
    .checkout-page .add-address
    {
        height: 190px;
    }
}
@media only screen and (max-width:443px) {
   
    .checkout-page .save-address , .your-address .add-address
    {
        height: 200px;
    }
    
}
@media only screen and (max-width :430px) {
    .checkout-page .right-side-details .order-summary-btn
    {
        font-size: 14px;
    }
    .checkout-page .left-side-details h2
    {
        font-size: 18px;
    }
    .showModal1 .howdy.guest-user-modal
    {
        width: 330px;
    }
    .showModal1 .guest-user-modal
    {
        width: 300px;
    }
    .showModal1 .guest-user-modal img
    {
        height: 100px;
    }
    .showModal1 .guest-user-modal p
    {
        font-size: 15px;
    }
    .checkout-page .save-address, .your-address .add-address
    {
        height: 212px;
    }
}
@media only screen and (max-width:364px) {
    .checkout-page .address-logo
    {
        font-size: 11px;
    }
    .checkout-page .default-address
    {
        font-size: 13px;
    }
}
@media only screen and (max-width:343px) {
   
    .checkout-page .save-address, .your-address .add-address
    {
        height: 200px;
    }
    
    .manage-checkout-address{
        max-height: 130px!important;
    }
}
@media only screen and (max-width:329px) {
   
    .checkout-page .save-address, .your-address .add-address
    {
        height: 230px;
    }
}


/* custom-option */
  
  .custom-select option {
    font-size: 18px; /* Set the same height as the select element */
  }

  .manage-checkout-address{
    max-height: 160px;
    overflow-y: auto;
  }

  .manage-checkout-address::-webkit-scrollbar {
    width: 5px;
  }

  .manage-checkout-address::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 10%);
    border-radius:5px;
  }

  .manage-checkout-address::-webkit-scrollbar-thumb:hover {
    background: #777; 
  }

.blog-header {
    padding: 40px 0;
}
.blog-header .blog-head-image
{
    position: relative;
}
.blog-header .blog-head-image img
{
  height: 350px;
  width: 100%;
  object-fit: cover;
}
.blog-header .blog-head-btn
  {
    display: flex;
    justify-content: center;
  }
.blog-header .blog-head-text-wrapper
{
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.55);
    padding: 35px 64px;
    width: 100%;
    height: 100%;
}
.blog-header .blog-head-text h1
{
    color: #fff;
}
.blog-header .blog-head-text p
{
  color: #f2f2f2;
  font-size: 16px;
}
.blog-header .blog-head-text .brdr-top
{
    border-top: 1px solid #b9b9b9;
}
.blog-header .blog-head-social
{
    display: flex;
    justify-content: space-between;
    line-height: 34px;
    align-items: center;
    padding: 15px 0;
}
.blog-header .blog-head-social-image span , .blog-header .blog-head-social-image a
{
    color: gray;
}
.blog-header .blog-head-social-image a:hover
{
  color: #B86097;
}

.blog-section .single-blog-title{
  margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    color: #202020;
    text-align: center;
    font-family: 'Lato', sans-serif;

}


/* responsive */

@media only screen and (max-width : 1399px)
{

}
@media only screen and (max-width : 1199px)
{
  .blog-header .blog-head-btn .login-btn
  {
    font-size: 13px!important;
  }
}
@media only screen and (max-width : 1024px)
{
}
@media only screen and (max-width : 991px)
{
  .blog-header .blog-head-text h1
  {
    padding: 14px 0;
    font-size: 26px;
  }
  .blog-header .blog-head-btn
  {
    display: flex;
    justify-content: center;

  }
  .blog-header .blog-head-btn .login-btn
  {
    font-size: 12px!important;
  }
  .blog-header .blog-head-text-wrapper
  {
    padding: 10px 50px;
  }
  .common-heading
  {
    font-size: 25px;
  }
}
@media only screen and (max-width : 767px)
{
  .blog-header .blog-head-btn
  {
    display: flex;
    justify-content: center;
  }
  .blog-header .blog-head-social
  {
    line-height: 0;
    padding: 10px 0;
  }
}
@media only screen and (max-width : 575px)
{
  .blog-header
  {
    padding: 20px 0;
  }

}
@media only screen and (max-width : 480px)
{
  .blog-header .blog-head-text-wrapper
  {
    padding: 0 40px;
  }
  .login-btn {
    font-size: 14px !important;
    padding: 8px 15px;
}
.blog-header .blog-head-text h1
  {
    font-size: 22px;
  }
  .blog-header .blog-head-image img
  {
    height: 350px;
  }
  .blog-header .blog-head-btn .login-btn
  {
    margin-top: 15px;
  }
  .blog-section .blog-image img
  {
    height: 120px;
  }
  .blog-section .blog-image .blog-color-changed-2 a
  {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width : 430px)
{
  .blog-section .blog-image .blog-color-changed-2 a
  {
    font-size: 13px !important;
  }
  .blog-section .blog-image .blog-color-changed-1
  {
    font-size: 12px;
  }
  .blog-section .blog-image .blog-text p
  {
    font-size: 12px;
  }
  .blog-section h4
  {
    font-size: 12px;
  }
  .blog-header .blog-head-social-image span, .blog-header .blog-head-social-image a
  {
    font-size: 13px !important;
  }
  .login-btn
  {
    font-size: 13px !important;
    padding: 6px 10px;
  }
  .blog-header .blog-head-text h1
  {
    font-size: 17px;
  }
  .common-heading
  {
    font-size: 18px;
  }
  .blog-section .blog-image img
  {
    height: 100px;
  }
}
@media only  screen and (max-width: 382px){
  .blog-header .blog-head-image img
  {
    height: 420px;
  }
 
}
@media only screen and (max-width : 320px)
{
  .blog-header
  {
    font-size: 10px;
  }
  .blog-header .blog-head-image img
  {
    height: 176px;
  }
  .blog-header .blog-head-social-image span, .blog-header .blog-head-social-image a
  {
    font-size: 10px !important;
  }
  .blog-header .blog-head-text h1
  {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .login-btn
  {
    padding: 5px 10px !important;
  }
  .common-heading
  {
    font-size: 14px;
  }
  .blog-section .blog-image img
  {
    height: 90px;
  }
  .blog-section h4
  {
    font-size: 8px;
  }
  .blog-section .blog-image .blog-color-changed-1
  {
    font-size: 5px;
  }
  .blog-section .blog-image
  {
    font-size: 8px;
  }
  .blog-section .blog-image .blog-color-changed-2 a
  {
    font-size: 11px !important;
  }
}
.no-order-page .no-order-wrapper
{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
}
.no-order-page .no-order-wrapper img
{
    padding-top: 41px;
    height: 110px;
    width: 100%;
    object-fit: contain;
}
.no-order-page .order-heading
{
    font-family: 'Playfair Display', serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0.50px;
    padding-top: 30px;
    color: #5a5a5a;
}
.orderlist-wrapper
{
    margin: 6px 0;
}
.new-order-style
{
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 25px;
}
.new-order-style .orderlist
{
    display: flex;
    text-align: center;
}
.new-order-style .accordion__button
{
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    padding: 5px 0;
}
.new-order-style .accordion__button:before
{
    position: absolute;
    right: 0;
    top: 45%;
}
.new-order-style .animal-static-image
{
    height: 50px;
    width: 100%;
    object-fit: contain;
}
.new-order-style .accordion__panel
{
    padding: 0;
}
.new-order-style .order-list-second-heading
{
    display: flex;
    padding: 20px 0;
}
.new-order-style .order-list-second-heading p
{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #424242;
    margin-bottom: 0;
    text-align: center;
}
.new-order-style .order-list-second-heading h6
{
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    font-family: 'Lato', sans-serif;
}
.new-order-style .order-total-value
{
    display: flex;
    justify-content: end;
    border-top: 1px solid #f2f2f2;
    padding: 12px 0;
    padding-right: 50px;
    border-bottom: 1px solid #f2f2f2;
}
.new-order-style .order-total-value p
{
    margin-bottom: 0;
    color: #424242;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.new-order-style .new-order-fam
{
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    margin-bottom: 0;
    letter-spacing: 0.60px;
    color: #212121;
}
.new-order-style .new-order-fam-2
{
    margin-bottom: 0;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #424242;
    padding: 16px 0;
    text-transform: capitalize;
}
.new-order-style .accordion__button:before
{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.new-order-style .accordion__button[aria-expanded='true']::before, .new-order-style .accordion__button[aria-selected='true']::before
{
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
}

/* responsive */

@media only screen and (max-width : 772px) {
    .new-order-style .order-total-value
    {
        padding-right: 30px;
    }
}

@media only screen and (max-width : 767px) {
    .new-order-style
    {
        padding-left: 0 !important;
    }
}
@media only screen and (max-width : 480px) {
    .new-order-style
    {
        padding-left: 0 !important;
    }
    .new-order-style .new-order-fam-2
    {
        padding: 5px 0;
        font-size: 13px;
    }
    .new-order-style
    {
        padding-top: 10px;
    }
    .new-order-style .new-order-fam
    {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
    .new-order-style .orderlist
    {
        text-align: start;
    }
    .new-order-style .accordion__button
    {
        padding: 0;
    }
    .new-order-style .order-list-second-heading p
    {
        text-align: start;
    }
    .new-order-style .order-list-second-heading h6
    {
        text-align: start;
    }
    .new-order-style .order-list-second-heading .product-image
    {
        text-align: center;
    }
    .new-order-style .order-list-second-heading .product-quantity
    {
        padding-left: 20px;
    }
}
@media only screen and (max-width : 430px) {
    .new-order-style .accordion__button:before
    {
        height: 8px;
        width: 8px;
    }
    .new-order-style .animal-static-image
    {
        padding-left: 10px;
    }
    .new-order-style .order-list-second-heading
    {
        padding: 5px 0;
    }
}
.profile-wrapper
{
    position: -webkit-sticky;
    position: sticky;
    top: 116px;
}
.profile-wrapper .profile-sidebar {
    /* border-top: 1px solid #B3B3B3 ; */
}
.profile-wrapper .sidebox {
    
    border-right: 1px solid #f2f2f2;
}
.profile-wrapper .profile-sidebar .sidebox li{
    padding: 14px 0;
    border-bottom: 1px solid #f2f2f2;
    list-style-type: none;
    margin-right: 24px;    
}
.profile-wrapper .profile-sidebar .sidebox li a{
    text-decoration: none;
    color: #212121;
    list-style: none;
    font-family: lato;
}
.profile-wrapper .profile-sidebar .sidebox li a:active , .profile-wrapper .profile-sidebar .sidebox li a:hover
{
    color: #B86097;
    font-weight: 500;
}
.profile-wrapper .profile-sidebar .sidebox li {
   list-style: none;
}

/* responsive css */

@media only screen and (max-width:767px) {
    .profile-wrapper .profile-sidebar .sidebox li
    {
        margin-right: 0 !important;
    }
    .profile-wrapper .user-multiple-tabs{
        display: flex;
        justify-content: space-around;
    }
}
.your-address
{
    padding-bottom: 60px;
}
.your-address .add-address {
    border-style: dashed;
    height: 250px;
    width: 100%;
    border-width: 2px;
    box-sizing: border-box;
    border-color: #C7C7C7;
    text-align: center;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.your-address h5
{
    padding-top: 37px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Playfair Display', serif;
}
.your-address .adress-boxes-2
{
    margin-top: 20px;
}
.your-address .add-address
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.your-address .add-address svg
{
    font-size: 32px;
    color: #d9d8d8;
    cursor: pointer;
}
.your-address .add-address p
{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: #424242de;
    padding-top: 15px;
    letter-spacing: 1px;
}
.your-address .save-address {
    height: 250px;
    width: 100%;
    border-width: 1px;
    border-radius: 8px;
    box-sizing: border-box;
    border-color: #C7C7C7;
    box-shadow: 0 3px 1px 0 rgb(0 0 0 / 10%);
    border-style: solid;
    margin-bottom: 20px;
    overflow-y: scroll;
}

.your-address .save-address::-webkit-scrollbar {
    width: 5px;
  }

   
  /* Handle */
  .your-address .save-address::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 10%);
    border-radius:5px;
  }
  
  /* Handle on hover */
  .your-address .save-address::-webkit-scrollbar-thumb:hover {
    background: #777; 
  }

.your-address .default-add {
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
}
.your-address .default-address
{
    font-size: 14px;
    color: #777;
    font-weight: 400;
    letter-spacing: 0.25px;
    font-family: 'Lato', sans-serif;
}
.your-address .address-logo
{
    font-size: 14px;
    color: #777;
    font-weight: 400;
    letter-spacing: 0.25px;
    font-family: 'Lato', sans-serif;
    padding-left: 5px;
}
.your-address .full-address
{
    padding: 10px;
}
.your-address .full-address .full-add-name
{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    margin-bottom: 5px;
    padding-top: 10px;
}
.your-address .full-address .full-address-1
{
    font-size: 13px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    margin-bottom: 2px;
    color: #777;
}
.your-address .full-address .full-add-phn
{
    font-size: 13px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    margin: 0;
    color: #777;
}
.your-address .full-address .full-editing-of-add
{
    padding-top: 20px;
    display: flex;
}
.your-address .full-address .full-editing-of-add .full-add-edit , 
.your-address .full-address .full-editing-of-add .full-add-remove
{
    text-decoration: none;
    color: #424242db;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
}
.your-address .full-address .full-editing-of-add .full-add-edit:hover , 
.your-address .full-address .full-editing-of-add .full-add-remove:hover
{
    color: #b86097;
}
.your-address .custom-edit-bar
{
    font-size: 14px;
    color: #212121;
}
.add-address-page h1,.add-address-page h2,.add-address-page h3,.add-address-page h4
{
    font-size: 16px;
    font-weight: 600;
    padding-top: 25px;
    padding-bottom: 10px;
    font-family: 'Playfair Display', serif;
}
.address-section .add-address-page input
{
    border: 1px solid #D8D7DF;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
}
.add-address-page label
{
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .7px;
    font-family: 'Lato', sans-serif;
}
.add-address-page textarea
{
    border: 1px solid #D8D7DF;
    width: 100%;
}
.full-address-btn
{
    border: none;
    color: #ffffff;
    background-color: #495057;
    outline: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 40px;
    width: auto;
    margin: 25px 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    
    position: relative;
    overflow: hidden;
}

.full-address-btn:focus{
   
    background-image: radial-gradient(circle, #212121, #1b1b1b, #141414, #0c0c0c, #000000);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

}



/* responsive css */

@media only screen and (max-width:818px) {
    .your-address .address-logo
    {
        font-size: 12px;
    }
    .your-address .default-address
    {
        font-size: 13px;
    }
    .your-address .default-add
    {
        padding: 4px 10px;
    }
    .your-address .save-address
    {
        /* margin-top: 30px; */
    }
    .your-address
    {
        padding-bottom: 30px;
    }
}
@media only screen and (max-width:767px) {
    .your-address .default-address
    {
        font-size: 14px;
    }
    .your-address .default-add
    {
        padding: 5px 10px;
    }
}
@media only screen and (max-width:637px) {
    .your-address .default-add
    {
        padding: 4px 10px;
    }
}
@media only screen and (max-width:575px) {
    .your-address .default-add
    {
        padding: 3px 10px;
    }

    .your-address .save-address,.your-address .add-address{
        width: 75%;
        margin:8px auto;
    }
}
@media only screen and (max-width:480px) {
    .your-address .default-add
    {
        padding: 2px 10px;
    }
    .your-address .save-address
    {
        height: 210px;
    }
    .your-address .full-address .full-editing-of-add
    {
        padding: 10px 0;
    }
    .your-address .full-address .full-add-name
    {
        padding-top: 0;
    }
    .your-address .add-address
    {
        height: auto;
        display: flex;
        flex-direction: row;
    }
   
    .your-address .add-address svg
    {
        font-size: 20px;
    margin-right: 5px;
    position: relative;
    bottom: 3px;
    }
    .your-address .add-address p
    {
        font-size: 16px;
    }
}
@media only screen and (max-width:443px) {
   
    .your-address .save-address , .your-address .add-address
    {
        height: auto;
    }
}
@media only screen and (max-width:373px) {
   
    .your-address .save-address , .your-address .add-address
    {
        height: 230px;
    }
}
@media only screen and (max-width:364px) {
    .your-address .address-logo
    {
        font-size: 11px;
    }
    .your-address .default-address
    {
        font-size: 13px;
    }
}
@media only screen and (max-width:343px) {
   
    .your-address .save-address, .your-address .add-address
    {
        height: 220px;
    }
   
    .your-address .add-address p
    {
        font-size: 13px;
    }
    .your-address .add-address svg
    {
        font-size: 22px;
    }
}
@media only screen and (max-width:324px) {
      
}
.single-blog-page
{
    padding: 50px 0;
}
.single-blog-page .single-blog-date p
{
    color: #41748D;
}
.single-blog-page .single-blog-image 
{
    cursor: pointer;
}
.single-blog-page .single-blog-image img
{
    height: 350px;
    width: 100%;
    object-fit: contain;
}
.single-blog-page .single-blog-header
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.single-blog-page .single-blog-share a
{
    color: gray;
}
.single-blog-page .single-blog-share a:hover
{
    color: #B86097;
}

.single-blog-page .blog-heading-page {
    font-size: 22px !important;
    display: flex;
    padding-bottom: 10px;
    text-align: start;
    /* text-transform: capitalize; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
}

.blog-body-heading{
    border: none;
    background: none;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #212121;
    padding: 0;
    margin-bottom: 0;
    font-family: 'Playfair Display', serif;
}

.blog-article-wrapper
{
    border: 1px solid #D8D7DF;
    padding: 20px;
    color: #848684;
    font-size: 14px;
}

/* Responsive css */
@media only screen and (max-width : 480px){

    .single-blog-para .common-para{
        font-weight: 400;
        font-size: 14px;
    }
}

@media only screen and (max-width : 575px) {
    .single-blog-page
    {
        padding: 30px 0;
    }
}

.carrer-page
{
    padding: 60px 0;
}

.carrer-page .carrer-banner-image {   
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.carrer-page .carrer-heading {
    font-family: 'Lato', sans-serif;
    color: #B86097;
    font-size: 30px;
    font-weight: 700;
}

.carrer-page .carrer-input {
    width: 50%;
    margin: 20px 0;
}

.carrer-page .carrer-btn {
    width: 30%;
}

@media only screen and (max-width:767px) {
    .carrer-page
    {
        padding: 20px 0;
    }
    .carrer-page .carrer-heading
    {
        font-size: 25px;
    }
}
@media only screen and (max-width:575px) {
    .carrer-page .carrer-heading
    {
        font-size: 20px;
    }
}
.search-page-wrapper
{

   padding-top: 20px;
}
.search-page-wrapper .search-header h2
{
    font-family:'Playfair Display', serif;;
    text-align: center;
    font-size: 36px;
    letter-spacing: 2px;
}
.search-page-wrapper .search-header p
{
    font-size: 14px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
}
.search-page-wrapper .search-input
{
    display: flex;
    padding: 5px 0;
}
.search-page-wrapper .search-input .search-input-btn
{
    border: none;
    color: #212121b3;
    padding: 6px 14px;
    border-top: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    background: none;
}
.search-page-wrapper .search-input .search-input-btn svg
{
    font-size: 18px;
}
.search-page-wrapper .search-input input
{
    border: 1px solid #f2f2f2;
    padding-left: 15px;
    text-transform: capitalize;
    font-family: 'Playfair Display';
    font-size: 14px;
    font-weight: 300;
}

/* ================= */
/* Global Css for page */

.cat-wrapper aside span {
   
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.cat-wrapper .sidebar-box h4 {
    
    font-style: normal;
    font-weight : 700;
    font-size : 18px;
    line-height: 21.78px;
    padding: 30px 15px;
}

.cat-wrapper  aside li p {
    padding-left: 15px;
}

.cat-wrapper .sidebar-box-content span {
    
    font-style: normal;
    font-weight : 600;
    font-size : 15px;
    line-height: 100%;
}
/* Page Border */

.cat-wrapper .prod-tag h2
{
    color: #FF585D;
    font-size: 21px;
    font-weight: 600;
    border-bottom: 1px solid #dfdada;
    padding-bottom: 15px;
}


.sidebar-box {
    
    border: 1px solid #B3B3B3;
    position: -webkit-sticky;
    position: sticky;
    top: 130px;
    cursor: pointer;
}
.sidebar-box aside li:hover
{
    color: #FF585D;
}
.sidebar-box aside li.active
{
    color: #FF585D;
    font-weight: 600;
}

/* Sidebar */
.sidebar {
    z-index: 0;
    min-height: 100vh;
    top: 0;
}

/* Categories Image and heading */

.cat-head {
position: relative;
}

.cat-head .cat-head-content {
    position: absolute;
    right: 2%;
    top: 50%;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
}

.cat-head .cat-head-content h1 {
    color: #FF585D;
    font-weight: 700;
}

/* Product Css */

.cat-wrapper .product-image img
{
    width: 100%;
    min-height: 210px;
    height: 246px;
    object-fit: contain;
    background: #ededed;
    padding: 5px;
}
.cat-wrapper .product-image
{
    margin-bottom: 10px;
    position: relative;
}
.cat-wrapper .product-image .product-image-wish-list button
{
    position: absolute;
    top: 1px;
    right: 0;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#6BA539;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 13px;
}
.cat-wrapper .product-image:hover button
{
    opacity: 1;
    visibility: visible;
}
.cat-wrapper .product-image .image-fit
{
    object-fit: contain;
}
.cat-wrapper .product-name 
{
    display: flex;
    justify-content: center;
}
.cat-wrapper .product-name .product-name-size
{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    color: #202020;
    text-align: center;
    
    font-family: 'Lato', sans-serif;
}
.cat-wrapper .product-name .product-name-sizes
{
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #5a5a5a;
    font-family: 'Lato', sans-serif;
}
.wishlist-icon:hover
{
    cursor: pointer;
}
 .wishlist-icon:hover button
{
    visibility: visible;
    opacity: 1;
}
 .product-name
{
    display: flex;
    justify-content: space-between;
}
.product-name .texts
{
   font-weight: 500;
   font-size: 16px;
}
 .card-button button
{
    /* position: absolute; */
    /* top: 6px;
    right: 6px; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 15px;
}
.wishlist-fs
{
    font-size: 25px;
}
 .Wishlist-hover-icon {
    color: red;
}
.pagination-lg .page-link
{
    font-size: 15px !important;
}


/* media query */

@media only screen and (max-width : 1920px)
{
    .cat-wrapper .product-image img
    {
        min-height: 220px;
    }
    
}

@media only screen and (max-width : 1399px)
{
    
}
@media only screen and (max-width : 1199px)
{

    .cat-head .cat-head-content {
        right: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        padding: 0 10px;
        font-size: 13px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 25px;
    }
    .cat-wrapper .sidebar-box h4
    {
        font-size: 21px;
    }
    .cat-wrapper .product-name .product-name-size
    {
        font-size: 14px;
    }
    .cat-wrapper .product-name .product-name-sizes
    {
        font-size: 14px;
    }
    .cat-wrapper .product-image img {
        min-height: 210px;
    }
    .wishlist-fs {
        font-size: 24px;
    }
}
@media only screen and (max-width : 1024px)
{
    .cat-head .cat-head-content
    {
        font-size: 12px;
    }
    .cat-wrapper .prod-tag h2
    {
        font-size: 19px;
    }
    .cat-wrapper .sidebar-box h4
    {
        font-size: 19px;
    }
    .cat-head .cat-head-content h1
    {
        font-size: 19px;
    }
    .cat-wrapper aside span
    {
        font-size: 13px;
    }
    .cat-wrapper .product-image img {
        min-height: 180px;
    }
    .cat-wrapper .product-name .product-name-size {
    font-size: 13px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 13px;
        }
        .wishlist-fs {
            font-size: 21px;
        }
}
@media only screen and (max-width : 991px)
{
    .cat-head .cat-head-content {
        font-size: 10px;
        top: 60%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 17px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .cat-wrapper .product-image img {
        min-height: 160px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 12px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 12px;
    }
    .wishlist-fs {
        font-size: 20px;
    }
}
@media only screen and (max-width : 767px)
{
    .cat-wrapper .product-image img {
        min-height: 190px;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 16px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 16px;
        margin-bottom: 0;
    }
    .cat-head .cat-head-content h1 {
        font-size: 16px;
    }
    .cat-wrapper aside span {
        font-size: 12px;
    }
    .wishlist-fs {
        font-size: 19px;
    }
    .cat-head .cat-head-content {
        font-size: 6px;
        top: 65%;
    }
}
@media only screen and (max-width : 575px)
{
    .search-page-wrapper .search-header h2
    {
        font-size: 30px;
    }
    .cat-head .cat-head-content {
        top: 70%;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 14px;
        padding: 20px 15px;
    }
    .cat-wrapper .cat-box-border {
        border-top: none;
    }
}
@media only screen and (max-width : 480px)
{
    .search-page-wrapper .search-header h2
    {
        font-size: 24px;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 14px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 14px;
    }
    .cat-wrapper aside span {
        font-size: 11px;
    }
    .cat-wrapper .product-image img {
        min-height: 160px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 11px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 11px;
    }
    .wishlist-fs {
        font-size: 17px;
    }
    .pagination-lg .page-link
    {
        font-size: 13px !important;
        padding: 5px 11px !important;
    }
}
@media only screen and (max-width : 430px)
{
    .cat-wrapper .sidebar-box h4 {
        font-size: 13px;
        padding: 15px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 12px;
    }
    .cat-head .cat-head-content {
        font-size: 5px;
        top: 72%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 13px;
        margin: 0;
    }
    .cat-wrapper .product-image img {
        min-height: 140px;
        padding: 5px;
    }
    .wishlist-fs {
        font-size: 14px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 10px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 10px;
    }
}
@media only screen and (max-width : 320px)
{
    .search-page-wrapper .search-header h2
    {
        font-size: 19px;
    }
    .cat-wrapper .product-image img {
        min-height: 120px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 11px;
    }
    .cat-wrapper aside span {
        font-size: 10px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 10px;
    }
    .cat-head .cat-head-content {
        font-size: 4px;
        top: 82%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 11px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 9px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 9px;
    }
    .cat-wrapper .product-image img {
        min-height: 106px;
    }
}
/* .password-reset-colors
{
    padding: 40px;
} */

.password-reset-single-page{
    background-color: aliceblue;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
}
.password-reset-single-page .eye-wrap{
    position: relative;
}

.password-reset-single-page .eye-wrapper{
    position: absolute;
    right: 10px;
    top: 18px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}



.password-reset-colors .password-reset-logo .logo-image
{
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.password-reset-colors .password-reset-logo img
{
    height: 100px;
    object-fit: contain;
}
.password-reset-colors .password-reset-logo h1
{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.50px;
    margin: 16px 0;
    text-transform: capitalize;
}
.password-reset-section
{
    width: 500px;
    border: 1px solid #f2f2f2;
    padding: 20px 100px 40px 100px;
    box-shadow: 2px 2px 30px rgb(0 0 0 / 10%);
    background-color: #fff;
}
.password-reset-section p
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
    padding-top: 10px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.password-reset-section .password-wrapper input
{
    display: block;
    margin: 16px 0;
    padding: 6px;
    width: 100%;
    border: 1px solid #f2f2f2;
}
.password-reset-section .password-wrapper input::-webkit-input-placeholder
{
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.25px;
}
.password-reset-section .password-wrapper input::placeholder
{
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.25px;
}
.password-reset-section .reset-btn button
{
    padding: 8px 0;
    width: 100%;
    background: #495057;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.25px;
    border: none;
}


/* responsive css */

@media only screen and (max-width:767px)
{
    .password-reset-section
{
    padding: 20px 50px 40px 50px;
}
}
@media only screen and (max-width:575px) 
{
    .password-reset-section
    {
        padding: 20px 50px 20px 50px;
    }
}
@media only screen and (max-width:480px) 
{
    .password-reset-section
    {
        padding: 10px 30px 10px 30px;
    }
}
.terms-condition-section
{
    padding: 30px 0;
}
.terms-condition-section .term-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.terms-condition-section ul li
{
    font-weight: 500;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.terms-condition-section .term-para .linking
{
    color: #777;
}
.shipping-policy-section
{
    padding: 30px 0;
}

.shipping-policy-section .shipping-content .ship-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.return-policy-section
{
    padding: 30px 0;
}

.return-policy-section .return-box .return-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.single-faq{
    padding: 50px 0;
    text-align: center;
}
.accordion{
  border:0;
}
.single-faq .accordion-item{
 
    border:0;
   
    border: 2px solid  #EAE6EE;
    margin-bottom: 1rem;
    box-shadow:0 5px 15px rgba(0, 0, 0, 0.1);
}
.single-faq .accordion .accordion-button:focus{
    box-shadow: none;
}
.single-faq .accordion .accordion-button:not(.collapsed){

    background-color: #fafafa;
    color: #B86097;
}
.single-faq .accordion .accordion-button::after{
 background-image: none;
}
.single-faq .accordion .accordion-header{
 display: flex;
 align-items: center;
 position: relative;
 z-index: 4;


}
.single-faq .accordion .accordion-header .accordion-button
{
    position: relative;
    font-size: 14px;
    font-weight: 500;
}
.single-faq .accordion .accordion-header span.two
{
    display: flex;

    background: #eae6ee;
    font-size: 14px;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    z-index:5;
 
}

.single-faq .accordion .accordion-header  .icon1{
    display: none;
}

.single-faq .accordion .accordion-button[aria-expanded="true"] .icon1{
    display: block;
}
.single-faq .accordion .accordion-button[aria-expanded="true"] .icon2{
    display: none;
}
.single-faq .accordion .accordion-body{
    text-align: left;
}


/* -------------------faq responsive----------------------- */
@media only screen and (max-width:575px){
        .single-faq{
            padding: 25px 0;
        }
        .single-faq .footer-inner-page-subheading{
             margin-bottom: 5px!important;
        }
        .single-faq .common-para-3{
            font-size: 13px;
        }
        .single-faq .accordion{
            margin-top: 15px;
        }
}


@media only screen and (max-width:520px){
    .single-faq .accordion .accordion-header span.one{
        margin-right: 20px;
    }  
}
@media only screen and (max-width:420px){
    .single-faq .accordion .accordion-header .accordion-button{
        padding: 10px 15px;
        font-size: 13px;
    }
    .single-faq .accordion .accordion-header span.two{
        height: 25px;
        width: 25px;
        font-size:12px;
    }
}
.policy-condition-section
{
    padding: 30px 0;
}
.policy-condition-section .policy-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.policy-condition-section ul li
{
    font-weight: 500;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.policy-condition-section .policy-para .linking
{
    color: #777;
}
.order-success-page
{
    padding-top: 50px;
}
.order-success-page .order-success-card
{
    background-color: #e1e1e1;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
}
.success-heading h1
{
    font-family: 'Playfair Display', serif;
    font-size: 26px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
}
.success-thank-you h2
{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    color: #000;
    letter-spacing: 0.25px;
}
.order-success-page .success-home-btn
{
    background: #4D4D4D;
    padding: 6px 30px;
    border: none;
    border-radius: 2px;
    margin-top: 20px;
}
.order-success-page .success-home-btn a
{
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    text-decoration: none;
}
.success-svg 
{
    padding: 8px 12px;
    background-color: #0E912C;
    border: none;
    border-radius: 50%;
    margin: 20px 0;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.success-svg svg
{
    color: #fff;
}
.success-order-id p, .success-email-confirm-msg p
{
    font-family: 'Lato', sans-serif;
    color: #5a5a5a;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.15px;
}


/* responsive */

@media only screen and (max-width : 767px) {
    .order-success-page .order-success-card
    {
        padding: 30px 15px;
    }
    .success-heading h1
    {
        text-align: center;
        font-size: 24px;
    }
    .success-order-id p, .success-email-confirm-msg p
    {
        text-align: center;
    }
}
@media only screen and (max-width : 480px) {
    .success-heading h1
    {
        font-size: 20px;
    }
}

@media only screen and (max-width : 430px) {
    .order-success-page .success-home-btn a {
        font-size: 12px;
    }
    .order-success-page .success-home-btn
    {
        margin-top: 0;
    }
    .success-thank-you h2
    {
        text-align: center;
        font-size: 14px;
    }
    .success-heading h1
    {
        font-size: 15px;
    }
}

@media only screen and (max-width : 371px) {
    .order-success-page .success-home-btn a
    {
        font-size: 12px;
    }
}
@media only screen and (max-width : 338px) {
    .order-success-page .success-home-btn a
    {
        font-size: 12px;
    }
    .order-success-page .success-home-btn
    {
        padding: 5px 15px;
    }
}
