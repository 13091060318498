.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  ._loading_overlay_content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }
  