/* wishlist button */

.unavailable-image{
    height: 60px!important;
}
.cart-section .cart-left-wrap .wishlist-close-icons-wrapper
{
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0;
}
.cart-section .cart-left-wrap .wishlist-btn {
    border: none;
    color: #D8D7DF;
    padding: 0;
    cursor: pointer;
    font-size: 26px;
    background: none;
    transition: 0.6s all;
}
.cart-section .cart-left-wrap .wishlist-btn svg:hover {
    color: #B86097;
    cursor: pointer;
}
.wishlisted-btn  {
    color: #B86097 !important;
}
.not-wishlist-btn {
    color: #D8D7DF;
}
.cart-section .cart-left-wrap .quantity-input input
{
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.cart-section .cart-left-wrap .quantity-button button{
    border: 0;
    background: #fff;
    width: 80%;
    padding: 5px 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.cart-section .cart-left-wrap .quantity-button button svg{
    font-size:10px;
}

.cart-section 
{
    padding: 30px 0;
}
.cart-section .cart-left-wrap
{
    border-bottom: 1px solid #EAEAEC;
    margin: 15px;
    position: relative;
    height: 200px;
}
.cart-section .cart-left-wrapper .cart-left-image img
{
    position: relative;
    height: 170px;
    width: 170px;
    object-fit: cover;
    background: #2121;
    padding: 5px;
    cursor: pointer;
}

.cart-section .cart-left-wrap .cart-right-image-text
{
    line-height: 31px;
}
.cart-section .cart-left-wrap .cart-trash-icon
{
    padding: 0;
    margin-left: 10px;
    cursor: pointer;
    font-size: 25px;
    border: none;
    color: #706f6f;
    background: none;
    transition: 0.6s all;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-heading
{
    font-weight: 600;
    width: 82%;
    text-transform: capitalize;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-para
{
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #444444;
    margin: 0;
    line-height: 1.5;
}
.cart-para::first-line
{
    text-transform: capitalize;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-para-base
{
    font-size: 13px;
    color: #94969f;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-quantity
{
    font-weight: 600;
    background: #f5f5f6;
    padding: 6px 10px;
}
.cart-section .cart-left-wrap .cart-right-image-text .cart-price
{
    font-weight: 600;
}

.cart-section .cart-right-wrapper
{
    padding: 20px;
    border: 1px solid #E4E1D9;
    position: sticky;
    top: 150px;
}
.cart-section .cart-right-wrap .cart-price-detail
{
    font-size: 15px;
    font-weight: bold;
}
.cart-section .cart-right-wrap .cart-total-price , .cart-section .cart-right-wrap .cart-discount-price , .cart-section .cart-right-wrap .cart-delivery-price
{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}
.cart-section .cart-right-wrap .cart-total-amount
{
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E4E1D9;
    padding: 15px 0;
}
.cart-section .cart-right-wrap .cart-order-btn
{
    width: 100%;
    padding: 10px 20px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background: #495057;
}
.cart-section .cart-right-wrap .continue-shop-btn
{
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #495057 !important;
    text-transform: uppercase;
    font-weight: 600;
    color: #495057 !important;
    background: #fff !important;
    font-size: 14px;
}
.cart-section .cart-right-wrap .continue-shop-btn:hover {
   
   color: #fff !important;
   background: #495057 !important;

}
.cart-section .cart-right-wrap .cart-order-btn:hover
{
    background: #495057;
}


/* Empty cart */

.cart-section .wishlist-card {
    justify-content: center;
    text-align: center;
    padding: 80px 0;
    
}
.cart-section .wishlist-card button {
    color : #fff;
    padding: 10px 30px;
    border: none;
    background: #4D4D4D;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 18px;
}
.cart-section .wishlist-card .wishlist-empty-icon img
{
    height: 105px;
    width: 90px;
    margin: 20px 0;
}
.cart-section .wishlist-card-text
{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
}
.cart-section .wishlist-card-text span{
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 24px;
    font-family: 'Lato', sans-serif;
}

.cart-section .wishlist-para
{
    color: #95989F;
}
.sold-out
{
    background-color: #ed1b24;
    padding: 4px 15px;
    font-size: 10px;
    color:#fff;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    line-height: 1.5;
    font-style: italic;
    border-radius: 4px;
    align-items: center;
    border: 0;
    border: 1px solid #ed1b24;
    justify-content: center;
}

/* --------tooltip remove css------- */
.__react_component_tooltip.show{
    display: none!important;
}




@media (hover:none) {
    .cart-section .cart-left-wrap .wishlist-btn svg:hover {
        color: inherit;
    }
}


/* responsive */

@media only screen and (max-width:991px){
    .cart-section .cart-left-wrap .cart-right-image-text .cart-heading
    {
        width: 70%;
        line-height: 1.3;
    }
}

@media only screen and (max-width:890px) {
    .cart-section .cart-right-wrap .cart-order-btn
{
    font-size: 14px;
}
}

@media only screen and (max-width:818px) {
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 12px;
    }
}

@media only screen and (max-width:767px){
    .cart-section .wish-trash-icon-color
{
    display: flex;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #a78b9c;
}
.cart-section .cart-left-wrap .wishlist-btn,.cart-section .cart-left-wrap .cart-trash-icon{
    font-size:21px;
}
    .cart-section .cart-left-wrap .wishlist-close-icons-wrapper
    {
        top: 10px;
        right: 0px;
    }
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 300px;
        width: 100%;
        object-fit: contain;
    }
    .cart-section .cart-left-wrap .change-in-padding
    {
        padding: 0;
    }
    .cart-section .cart-left-wrap .cart-trash-icon
    {
        top: 10px;
        right: 2px;
    }
    .cart-section .cart-left-wrap
    {
        height: 450px;
    }
    .cart-section .cart-left-wrap .cart-right-image-text .cart-heading
    {
        width: 100%;
        line-height: unset;
    }
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 15px;
    }
}

@media only screen and (max-width:575px){
    
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 15px;
    }
    .cart-section .wishlist-card
    {
        padding: 20px 0;
    }
    
    .cart-section .cart-left-wrap .wishlist-close-icons-wrapper {
        top: 8px;
        right: 0;
    }
}

@media only screen and (max-width:480px){
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 252px;
    }
   
    .cart-section .cart-right-wrap .cart-order-btn
    {
        font-size: 14px;
    }
    .cart-section .cart-left-wrap
    {
        height: auto;
    }
}

@media only screen and (max-width:430px){
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 227px;
    }
    .cart-section .cart-left-wrap
    {
        height: auto;
    }
}

@media only screen and (max-width:355px){
    .cart-section .cart-left-wrap{
        height: auto;
    }
    
}

@media only screen and (max-width:354px){
    .cart-section .cart-left-wrap .cart-right-image-text
    {
        line-height: 24px;
    }
}

@media only screen and (max-width:320px){
    .cart-section .cart-left-wrap .cart-left-image img
    {
        height: 180px;
    }
    .cart-section .cart-right-wrap .cart-order-btn {
        padding: 8px 15px;
        font-size: 12px;
    }
    .cart-section .cart-left-wrap
    {
        height: auto;
    }
}