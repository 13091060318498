/* Global Css for page */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Montserrat&family=Raleway:wght@600;700&display=swap');

.cat-wrapper
{
    padding: 5px 0;
}
.cat-wrapper aside span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.cat-wrapper .sidebar-box h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight : 700;
    font-size : 18px;
    line-height: 21.78px;
    padding: 30px 15px;
}

.cat-wrapper  aside li p {
    padding-left: 15px;
}

.cat-wrapper .sidebar-box-content span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight : 600;
    font-size : 15px;
    line-height: 100%;
}
/* Page Border */

.cat-wrapper .prod-tag h2
{
    color: #FF585D;
    font-size: 21px;
    font-weight: 600;
    border-bottom: 1px solid #dfdada;
    padding-bottom: 15px;
}
.cat-wrapper .bred-crumb-one{

    color: #777;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
}
.cat-wrapper .separator{
    margin: 0 5px;
}
   .cat-wrapper .bred-crumb-two
   {

    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
   }
   .cat-wrapper hr{
    border-top: 1px solid #e6dbdb;
   }




.sidebar-box {
    
    border: 1px solid #B3B3B3;
    /* border-top: 0 !important; */
    position: sticky;
    top: 130px;
    cursor: pointer;
}
.sidebar-box aside li:hover
{
    color: #FF585D;
}
.sidebar-box aside li.active
{
    color: #FF585D;
    font-weight: 600;
}

/* Sidebar */
.sidebar {
    z-index: 0;
    min-height: 100vh;
    top: 0;
}

/* Categories Image and heading */

.cat-head {
position: relative;
}

.cat-head .cat-head-content {
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-50px);
}

.cat-head .cat-head-content h1 {
    color: #FF585D;
    font-weight: 700;
}

/* Product Css */

.cat-wrapper .product-image img
{
    width: 100%;
    min-height: 210px;
    height: 246px;
    object-fit: contain;
    background: #ededed;
    padding: 5px;
}
.cat-wrapper .product-image
{
    margin-bottom: 10px;
    position: relative;
}
.cat-wrapper .product-image .product-image-wish-list button
{
    position: absolute;
    top: 1px;
    right: 0;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#6BA539;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 13px;
}
.cat-wrapper .product-image:hover button
{
    opacity: 1;
    visibility: visible;
}
.cat-wrapper .product-image .image-fit
{
    object-fit: contain;
}
.cat-wrapper .product-name 
{
    display: flex;
    justify-content: space-between;
}
.cat-wrapper .product-name .product-name-size
{
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.7px;
   font-family: 'Lato', sans-serif;
    color: #202020;
 
    text-align: center;
    
}

.wishlist-icon:hover
{
    cursor: pointer;
}
 .wishlist-icon:hover button
{
    visibility: visible;
    opacity: 1;
}
 .product-name
{
    display: flex;
    justify-content: space-between;
}
.product-name .texts
{
   font-weight: 500;
   font-size: 16px;
}
 .card-button button
{
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 15px;
}
.wishlist-fs
{
    font-size: 25px;
}
 .Wishlist-hover-icon {
    color: red;
}




/* media query */

@media only screen and (max-width : 1920px)
{
    .cat-wrapper .product-image img
    {
        min-height: 220px;
    }
    .cat-wrapper .product-image img
{
    
    height: 246px;
    object-fit: contain;
}
}

@media only screen and (max-width : 1399px)
{
    
}
@media only screen and (max-width : 1199px)
{

    .cat-head .cat-head-content {
        right: 0;
        transform: translateY(-30px);
        padding: 0 10px;
        font-size: 13px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 25px;
    }
    .cat-wrapper .sidebar-box h4
    {
        font-size: 21px;
    }
    .cat-wrapper .product-name .product-name-size
    {
        font-size: 14px;
    }
    .cat-wrapper .product-name .product-name-sizes
    {
        font-size: 14px;
    }
    .cat-wrapper .product-image img {
        min-height: 210px;
    }
    .wishlist-fs {
        font-size: 24px;
    }
}
@media only screen and (max-width : 1024px)
{
    .cat-head .cat-head-content
    {
        font-size: 12px;
    }
    .cat-wrapper .prod-tag h2
    {
        font-size: 19px;
    }
    .cat-wrapper .sidebar-box h4
    {
        font-size: 19px;
    }
    .cat-head .cat-head-content h1
    {
        font-size: 19px;
    }
    .cat-wrapper aside span
    {
        font-size: 13px;
    }
    .cat-wrapper .product-image img {
        min-height: 180px;
    }
    .cat-wrapper .product-name .product-name-size {
    font-size: 13px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 13px;
        }
        .wishlist-fs {
            font-size: 21px;
        }
}
@media only screen and (max-width : 991px)
{
    .cat-head .cat-head-content {
        font-size: 10px;
        top: 60%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 17px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .cat-wrapper .product-image img {
        min-height: 160px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 12px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 12px;
    }
    .wishlist-fs {
        font-size: 20px;
    }
}
@media only screen and (max-width : 767px)
{
    .cat-wrapper .product-image img {
        min-height: 190px;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 16px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 16px;
        margin-bottom: 0;
    }
    .cat-head .cat-head-content h1 {
        font-size: 16px;
    }
    .cat-wrapper aside span {
        font-size: 12px;
    }
    .wishlist-fs {
        font-size: 19px;
    }
    .cat-head .cat-head-content {
        font-size: 6px;
        top: 65%;
    }
}
@media only screen and (max-width : 575px)
{
    .cat-head .cat-head-content {
        top: 70%;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 14px;
        padding: 20px 15px;
    }
    .cat-wrapper .cat-box-border {
        border-top: none;
    }
}
@media only screen and (max-width : 520px)
{
    .cat-wrapper .product-image img
    {
        padding: 0!important;
        background: transparent!important;
        min-height: 180px!important;
        height: 235px!important;
    }
}
@media only screen and (max-width : 480px)
{
    .cat-wrapper .prod-tag h2 {
        font-size: 14px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 14px;
    }
    .cat-wrapper aside span {
        font-size: 11px;
    }
    .cat-wrapper .product-image img {
        min-height: 160px!important;
        height: 200px!important;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 11px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 11px;
    }
    .wishlist-fs {
        font-size: 17px;
    }
    
}
@media only screen and (max-width : 430px)
{
    .cat-wrapper .sidebar-box h4 {
        font-size: 13px;
        padding: 15px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 12px;
    }
    .cat-head .cat-head-content {
        font-size: 5px;
        top: 72%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 13px;
        margin: 0;
    }
    /* .cat-wrapper .product-image img {
        min-height: 140px;
        padding: 5px;
    } */
    .wishlist-fs {
        font-size: 14px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 10px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 10px;
    }
    .cat-wrapper .product-image img {
        min-height: 160px!important;
        height: 190px!important;
    }
}

@media only screen and (max-width : 380px)
{
    .cat-wrapper .product-image img {
        min-height: 140px!important;
        height: 160px!important;
    }
}

@media only screen and (max-width : 320px)
{
    .cat-wrapper .product-image img {
        min-height: 120px;
    }
    .cat-wrapper .sidebar-box h4 {
        font-size: 11px;
    }
    .cat-wrapper aside span {
        font-size: 10px;
    }
    .cat-head .cat-head-content h1 {
        font-size: 10px;
    }
    .cat-head .cat-head-content {
        font-size: 4px;
        top: 82%;
    }
    .cat-wrapper .prod-tag h2 {
        font-size: 11px;
    }
    .cat-wrapper .product-name .product-name-size {
        font-size: 9px;
    }
    .cat-wrapper .product-name .product-name-sizes {
        font-size: 9px;
    }
    
    .cat-wrapper .product-image img {
        min-height: 160px!important;
        height: 180px!important;
    }
}