.carousel-demo .product-item .product-item-content {
    position: relative;
    border-radius: 3px;
    margin: .3rem;
    padding: 5px;
    cursor: pointer;
}
.carousel-demo .product-item .product-item-content .card-button button
{
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background:#78BE20;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition:.4s;
    font-weight: 600;
    font-size: 15px;
}
.carousel-demo .product-item .product-item-content:hover button
{
    visibility: visible;
    opacity: 1;
}
.carousel-demo .product-item .product-item-content .product-name
{
    display: flex;
    justify-content: space-between;
}
.carousel-demo .product-item .product-item-content .product-name .add-button button
{
    border: none;
    background: none;
    line-height: 28px;
}
.carousel-demo .product-item .product-item-content .product-name .add-button .btn-icon
{
    color: #78BE20;
    font-weight: 400;
    font-size: 35px;
}

.carousel-demo .product-item .product-item-content .product-name .add-button .btn-icon:hover
{
    color: #6BA539;
}
.carousel-demo .product-item .product-item-content h4
{
    font-size: 16px;
    font-weight: 600;
}
.carousel-demo .product-item .product-item-content .product-badge
{
    display: none;
}
.carousel-demo .card
{
    border: none;
}
.carousel-demo .p-carousel-indicators 
{
    display: none;
}
.carousel-demo .product-item .product-image {
    width: 100%;
    height: 220px;
    object-fit: cover;
    padding: 15px;
    background: #ededed;
}
.carousel-demo .product-item .product-item-content:hover .Wishlist-hover-icon {
    color: red;
}





/* responsive */


@media only screen and (max-width : 991px)
{
    .carousel-demo .product-item .product-image {
        width: 100%;
        height: 210px;
    }
    .carousel-demo .product-item .product-item-content {
        height: 310px;
    }
    .carousel-demo .product-item .product-item-content h4
{
    font-size: 11px;
    font-weight: 700;
}
.carousel-demo .product-item .product-item-content h6 {
    font-size: 14px;
}
.carousel-demo .product-item .product-item-content .product-name .add-button .btn-icon {
    font-size: 28px;
}
}

@media only screen and (max-width : 767px)
{
    .carousel-demo .product-item .product-image {
        width: 100%;
        height: 148px;
    }
    .carousel-demo .product-item .product-item-content {
        height: 255px;
    }
}

@media only screen and (max-width : 480px)
{
    .carousel-demo .product-item .product-image {
        width: 100%;
        height: 215px;
    }
}