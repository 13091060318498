.return-policy-section
{
    padding: 30px 0;
}

.return-policy-section .return-box .return-para
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}