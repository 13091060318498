
.about-page
{
    padding: 60px 0;
}

.about-section-2
{
    background: #F7F7F7;
    padding: 60px 0 80px 0;
}
.about-section-2 .about-section-2-image img
{
    height: 250px;
    width: 100%;
    object-fit: cover;
}

.about-section-3
{
    padding: 60px 0;
}
.about-section-3 .our-team-right-carousel .p-carousel-container
{
    background: none;
}
.about-section-3 .our-team-right-carousel .product-item-1 .product-item-content-1
{
    background: none;
    box-shadow: none;
    padding: 15px 0;
    margin: 0;
}
.about-section-3 .our-team-right-carousel .product-item-1 .product-image-1
{
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.about-section-4
{
    padding: 80px 0;
}
.about-section-4 .our-vision-left-image img
{
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.about-section-5
{
    padding: 60px 0;
}
.about-section-5 .easy-payment-right-image img
{
    height: 500px;
    width: 100%;
    object-fit: cover;
}
.about-section-6
{
    padding: 60px 0;
    background: #F7F7F7;
}
.about-page .about-right-text
{
    padding-left: 40px;
}
.our-team-right-carousel
{
    padding-left: 40px;
}
.p-carousel-container
{
    background: #F7F7F7;
}
.product-item-1
{
    display: flex;
    align-items: center;
}
.product-item-1 .product-item-content-1
{
    background: #fff;
    position: relative;
    padding: 5px;
    margin: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 360px;
}
.product-item-1 .product-image-1
{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}
.product-item-1 .colour-change
{
    color: #41748D;
    padding-top: 15px;
    font-family: 'Lato', sans-serif;
    height: calc(100% - 165px);
    overflow: auto;
}
.product-item-1 .colour-change::-webkit-scrollbar {
    display:none;
  }

.product-item-1 .colour-change-1
{
    color: #757575;
    padding-top: 6px;
    font-family: 'Playfair Display', serif;
     position: absolute;
     left:50%;
     transform: translateX(-50%);
     bottom: 20px;

}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    
    border: none;
    color: #343a40;
    background: #e9ecef;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
    width: 30px !important;
    height: 30px !important;
    z-index: 4 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    position: relative;
    
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
   
    border-color: transparent;
    
}
.p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus
{
    box-shadow: none;
}
/* ------------iphone------------------------- */


.p-carousel .p-carousel-content .p-carousel-next .pi-chevron-right:before
{
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #343A4F;
    border-left: 2px solid #343A4F;
    transform: rotate(134deg);
    position: relative;
    right: 2px;
    bottom: 1px;
    
}

.p-carousel .p-carousel-content .p-carousel-prev .pi-chevron-left:before
{
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #343A4F;
    border-left: 2px solid #343A4F;
    transform: rotate(-48deg);
    position: relative;
    left: 2px;
    bottom: 1px;
}


/* --------------iphone end--------------- */


/* responsive */

@media only screen and (max-width:767px) {
.about-page
{
    padding: 30px 0;
}
.about-page .about-left-image img
{
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.about-page .about-right-text
{
    padding-left: 0;
}
.common-heading-2
{
    margin: 0;
}
.about-section-2
{
    padding-top: 30px;
}
.about-section-3
{
    padding: 30px 0;
}
.our-team-left-text
{
    padding-bottom: 30px;
}
.about-section-4
{
    padding: 30px 0;
}
.about-section-4 .our-vision-left-image img
{
    height: 300px;
}
.about-section-5
{
    padding: 0;
}
.about-section-5 .easy-payment-right-image img
{
    padding: 30px 0;
    height: 300px;
}
.about-section-6
{
    padding: 30px 0;
}
}




@media only screen and (max-width:575px) {
    .our-team-right-carousel{
        padding-left: 0;
    }
}


@media only screen and (max-width:430px) 
 {
    .about-page .about-left-image img
{
    height: 210px;
}
.common-heading-2
{
    font-size: 19px;
}
.about-section-2
{
    padding-top: 0;
}
 }
