/* 404 page css */

.error-page {
    height: 100vh;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
}

.error-page-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-page-wrapper {
    text-align: center;
}

.error-page-wrapper h1 {
    font-size: 220px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    margin-bottom: 0;

}

.error-page-wrapper h1 span.two {
    color: #4d4d4d;
}

.error-page-wrapper h1 span.one {
    color: #000;
}

.error-page-msg {
    font-size: 22px;
    font-weight: 500;
    color: #525252;
}

.error-page-btn {
    display: inline-block;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid transparent;
    text-transform: uppercase;
    padding: 7px 25px;
    font-size: 14px;
    border-radius: 40px;
    margin: 7px;
    -webkit-transition: .2s all;
    transition: .2s all;
    font-family: 'Roboto', sans-serif;
}

.error-page-btn.home {
    background: #4d4d4d;
    color: #fff;
}

.error-page-btn.contact {
    border: 2px solid #4d4d4d;
    color: #525252;
}

@media only screen and (max-width : 576px)
{
    .error-page-wrapper h1 {
        font-size: 140px;
    }

    .error-page-msg {
        font-size: 18px;
    }
}
