/* .password-reset-colors
{
    padding: 40px;
} */

.password-reset-single-page{
    background-color: aliceblue;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
}
.password-reset-single-page .eye-wrap{
    position: relative;
}

.password-reset-single-page .eye-wrapper{
    position: absolute;
    right: 10px;
    top: 18px;
    transform: translateY(-50%);
}



.password-reset-colors .password-reset-logo .logo-image
{
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.password-reset-colors .password-reset-logo img
{
    height: 100px;
    object-fit: contain;
}
.password-reset-colors .password-reset-logo h1
{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.50px;
    margin: 16px 0;
    text-transform: capitalize;
}
.password-reset-section
{
    width: 500px;
    border: 1px solid #f2f2f2;
    padding: 20px 100px 40px 100px;
    box-shadow: 2px 2px 30px rgb(0 0 0 / 10%);
    background-color: #fff;
}
.password-reset-section p
{
    font-weight: 400;
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
    padding-top: 10px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.35px;
}
.password-reset-section .password-wrapper input
{
    display: block;
    margin: 16px 0;
    padding: 6px;
    width: 100%;
    border: 1px solid #f2f2f2;
}
.password-reset-section .password-wrapper input::placeholder
{
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.25px;
}
.password-reset-section .reset-btn button
{
    padding: 8px 0;
    width: 100%;
    background: #495057;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.25px;
    border: none;
}


/* responsive css */

@media only screen and (max-width:767px)
{
    .password-reset-section
{
    padding: 20px 50px 40px 50px;
}
}
@media only screen and (max-width:575px) 
{
    .password-reset-section
    {
        padding: 20px 50px 20px 50px;
    }
}
@media only screen and (max-width:480px) 
{
    .password-reset-section
    {
        padding: 10px 30px 10px 30px;
    }
}