.profile-wrapper
{
    position: sticky;
    top: 116px;
}
.profile-wrapper .profile-sidebar {
    /* border-top: 1px solid #B3B3B3 ; */
}
.profile-wrapper .sidebox {
    
    border-right: 1px solid #f2f2f2;
}
.profile-wrapper .profile-sidebar .sidebox li{
    padding: 14px 0;
    border-bottom: 1px solid #f2f2f2;
    list-style-type: none;
    margin-right: 24px;    
}
.profile-wrapper .profile-sidebar .sidebox li a{
    text-decoration: none;
    color: #212121;
    list-style: none;
    font-family: lato;
}
.profile-wrapper .profile-sidebar .sidebox li a:active , .profile-wrapper .profile-sidebar .sidebox li a:hover
{
    color: #B86097;
    font-weight: 500;
}
.profile-wrapper .profile-sidebar .sidebox li {
   list-style: none;
}

/* responsive css */

@media only screen and (max-width:767px) {
    .profile-wrapper .profile-sidebar .sidebox li
    {
        margin-right: 0 !important;
    }
    .profile-wrapper .user-multiple-tabs{
        display: flex;
        justify-content: space-around;
    }
}